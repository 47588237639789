<template>
           <tr class="fold">
             <td colspan="7">
               <div class="acor-body">
                 <div class="acor__user">
                   <div class="acor__user__information">
                     <div class="user__information">
                       <div class="user__photo">
                         <div>
                           <img style="width: 50%;
                                      border-radius: 100%; min-width: 150px;" :src="'https://api.roobo.pro/api/users/' + data.user.login + '/mini'">
                         </div>
                       </div>
                       <div class="user__text">
                         <p style="
                font-size: 14px;
                font-family: 'Roboto';
                color: rgb(102, 102, 102);
                font-style: italic;
                line-height: 1.2;
                text-align: left;">Пользователь</p>
                         <p style="
                font-size: 18px;
                font-family: 'Roboto';
                color: rgb(51, 51, 51);
                line-height: 0.778;
                text-align: left;
                margin: 10px 0px;
                font-weight: 500;
                margin-bottom: 10px;
            ">{{data.user.Name}} (ID: {{data.user.id}} )</p>
                         <p style="font-size: 16px;
                font-family: 'Roboto';
                color: rgb(51, 51, 51);
                line-height: 1.125;
                text-align: left;
                margin-bottom: 5px;"> {{data.user.nomer}} </p>
                         <p style="font-size: 14px;
                font-family: 'Roboto';
                color: rgb(82, 158, 244);
                line-height: 1.286;
                text-align: left;"> {{data.user.email}} </p>
                         <p v-if="data.service.item.referral" style="font-size: 14px;
                font-family: 'Roboto';
                color: rgb(82, 158, 244);
                line-height: 1.286;
                text-align: left;">Партнер - {{data.service.item.referral.referralUser.Name}}, email - {{data.service.item.referral.referralUser.email}} </p>
                         <p v-if="data.service.item.referral" style="font-size: 14px;
                font-family: 'Roboto';
                color: rgb(82, 158, 244);
                line-height: 1.286;
                text-align: left;">Выплачено партнеру - {{data.service.item.referral.forReferralMoney}} ₽</p>
                       </div>
                     </div>
                     <div class="user__items">
                       <div class="items__goods">
                         <div>
                           <div>
                             <p style="text-align: left;" v-if="data.service.type == 'sub'">Подписка</p>
                             <p style="text-align: left;" v-if="data.service.type == 'tovar'">Товар</p>
                             <p style="text-align: left;" v-if="data.service.type == 'post'">Пост</p>
                           </div>
                           <div v-if="data.service.type == 'sub'" class="itemsss">
                             <div  style="margin-right: 10px;text-align: left;">
                               <img style="width: 40%;" :src="
                    'https://api.roobo.pro/api/subscriptions/photo/' + data.service.item.id" alt="">
                             </div>
                             <div style="width: 300px;">
                               <p style="color: #333333;
                      font-size: 12px;
                      font-weight: 400;position: relative;
    left: -100px;"> {{data.service.item.tittle}} </p>
                             </div>
                           </div>
                           <div v-if="data.service.type == 'tovar'"  class="itemsss">
                             <div style="margin-right: 10px;text-align: left;">
                               <img style="width: 40%;" :src="
                    'https://api.roobo.pro/api/publication/storage/' + data.service.item.img" alt="">
                             </div>
                             <div style="width: 300px;">
                               <p style="color: #333333;
                      font-size: 12px;
                      font-weight: 400;position: relative;
    left: -100px;"> {{data.service.item.tittle}} </p>
                             </div>
                           </div>
                           <div v-if="data.service.type == 'post'" class="itemsss">
                             <div style="width: 300px;text-align: left;">
                               <p style="color: #333333;
                      font-size: 12px;
                      font-weight: 400;"> {{data.service.item.tittle}} </p>
                             </div>
                           </div>
                           <div>
                           <p style="color: #666666;
                  font-size: 14px;text-align: left; cursor: pointer" @click="copyLinkinput(data.user.id)">Ссылка на оплату <b-icon
                             style="font-size: 25px; color: #fe6637"
                             class="letbut"
                             icon="link45deg"
                             title="Title"
                             variant="primary"
                           ></b-icon> <span style="color: #428bca;
    text-decoration: none;"> {{data.link}} </span> </p>
                           <textarea v-model="data.comment" placeholder="Введите комментарий"></textarea>
                           <b-button
                             v-if="data.comment"
                             variant="info"
                             style="margin-top: -45px; margin-left: 20px"
                             @click="updateComment(data.comment, data.id)"
                           >Установить комментарий
                           </b-button>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </td>
           </tr>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
    }
  },
    props: {
      data: {}
    },
  methods: {
      copyLinkinput(id) {
        let textLinkInput = document.getElementById(id)
        textLinkInput.select()
        document.execCommand('copy')
        alert("Текст успешно скопирован")
      },
    async updateComment(comment, id){
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      let result = await axios.post(`https://api.roobo.pro/api/users/statistic/addComment`, {
        pay_id: id,
        comment: comment
      }, config);

      if(result.statusCode){
        this.$toast.success(result.message, '', {
          position: "topCenter"
        });
        return;
      }

      this.$toast.success(result, 'Успешно', {
        position: "topCenter"
      });
    }
  },
    mounted() {

    },
}
</script>

<style>
.letbut {
  font-size: 24px;
  float: right;
  color: #fe6637 !important;
}
</style>
