<template>
  <div class="mmmaincont">
    <h2>Вывод Средств</h2>
    <div>
      <div class="glav">
        <div>
          <div class="formbg-outercard">
            <div class="formbgcard">
              <div class="form-innercard">
                <div class="creditcard">
                  <div style="width: 40px; margin-right: 15px">
                    <img src="../assets/credit-card.png" alt="" />
                  </div>
                  <div style="display: flex; flex-direction: column">
                    <span
                      style="
                        font-weight: 500;
                        font-size: 18px;
                        color: #0a2540;
                        font-family: sans-serif;
                      "
                      class="card__text"
                      >Номер вашей банковской карты</span
                    >
                    <span
                      style="
                        font-size: 15px;
                        font-family: sans-serif;
                        color: #0a2540b3;
                        text-align: left;
                      "
                      class="card__text"
                      >Для вывода средств</span>
                  </div>
                </div>
                <form>
                  <div class="fieldnumber">
                    <span style="margin-bottom: 0">Вывод денежных средств на карту осуществляется в течении 72 часов после формирования заявки на выплату!</span>
                    <br>
                    <span>Комиссия платежной системы 2.5% + 10% комиссия сервиса!</span><br>

                    <span style="margin-bottom: 0">Минимальная сумма вывода 1000 ₽ </span>
                    <br>

                    <span>Максимальная сумма вывода 15 000 ₽/день.</span>
                    <br>

                    <span style="font-size: 16px">
                      Доступно для вывода: {{Number(user.balance).toLocaleString()}} ₽
                    </span><br>


                    <label style="margin-top: 20px">Номер карты</label>
                    <masked-input v-model="addcreditcard" mask="1111 1111 1111 1111" placeholder="0000 0000 0000 0000" />
                  </div>
                  <div class="fieldnumber">
                    <label>Сумма для выплаты</label>
                    <input v-model="withdrawalAmount" />
                  </div>
                  <p v-if="error"></p>
                  <div class="fieldcard">
                    <a class="cardssub" @click="addCard">Добавить карту</a>
                  </div>
                  <div class="fieldcard">
                    <a class="cardssub" @click="withdraw">Запросить вывод</a>
                  </div>
                  <div class="fieldests">
                    <span
                      >Ваши данные защищены — мы никуда их не передаём. Нажимая
                      кнопку, вы принимаете условия сервиса.

                       Если в течение этого срока деньги так и не поступили, напишите нам на почту support@roobo.pro
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="contts">
          <div class="cardds" v-for="card in cardlist" :key="card.id">
            <div class="img_contts">
              <img src="../assets/card.jpg" alt="" />
            </div>
            <div class="righttps">
              <div class="tittlems">
                <div class="te">
                  <p>{{ card.type }}</p>
                  <p class="ds">{{ card.panmask }}</p>
                </div>
              </div>
              <div class="buttns">
                <!-- <button v-if="card.status == 'noactive'" v-on:click="active(card.id)">Выбрать</button> -->
                <button v-on:click="deletele(card.id)">Отвязать</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="maincont">
        <h2>История выплат</h2>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
        <div class="aaaaa">
          <b-table
            id="my-table"
            :fields="fields"
            :items="users"
            :per-page="perPage"
            :current-page="currentPage"
            small
          >
            <template #cell(Индекс)="data">
              {{ data.item.id }}
            </template>
            <template #cell(Услуга)="data">
              {{ JSON.parse(data.item.description).description }}
            </template>
            <template #cell(Сумма)="data">
              {{ JSON.parse(data.item.description).amount.value }}
            </template>
            <template #cell(Статус)="data"> Выплачено </template>
            <template #cell(Дата)="data">
              {{ data.item["createdAt"] }}
            </template>
          </b-table>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>

        <div v-if="!user.id">
          <p>Для добавления карты требуется авторизоваться</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "../api";
import { Auth } from "../auth";
import Vue from "vue";
import MaskedInput from 'vue-masked-input';

export default {
  name: "subscription",
  data() {
    return {
      space: true,
      user: {},
      cardlist: [],
      fields: ["Индекс", "Услуга", "Сумма", "Дата", "Статус"],
      users: {},
      perPage: 50,
      currentPage: 1,
      addcreditcard: "",
      error: "",
      withdrawalAmount: "",
    };
  },
  components: {
    MaskedInput
  },
  methods: {
    show() {
      this.$bvModal.show("modal-money");
    },
    async addCard() {
      const data = {
        numberCard: this.addcreditcard,
      };
      const result = await Api.users.linkCard(
        localStorage.getItem("token"),
        data
      );
      console.log(result);
      if(result){
        this.$toast.success(result, 'Запрос выполнен', {
          position: "topCenter"
        });
      }
    },
    async active(id) {
      const res = await Api.users.path(localStorage.getItem("token"), id);
      console.log(res);
      if (res) {
        let arrret = this.cardlist.map((item) => {
          if (item.id == id) {
            item.status = "active";
          } else {
            item.status = "noactive";
          }

          return item;
        });
        this.cardlist = arrret;
        console.log(this.cardlist);
      }
    },
    async deletele(id) {
      const res = await Api.users.deleteCard(
        localStorage.getItem("token"),
        id
      );
      console.log(res);
      if (res) {
        this.cardlist = this.cardlist.filter(function (item, index, array) {
          if (item.id != id) {
            return true;
          }
        });
        this.$toast.success(`${res}`, 'Запрос выполнен', {
          position: "topCenter"
        });
      }
    },
    async withdraw() {
      const res = await Api.users.withdrawMoney(localStorage.getItem("token"), {
        amount: this.withdrawalAmount,
        cardNumber: this.addcreditcard,
      });
      console.log(`res is`, res);
      if (res.statusCode === 417) {
        this.$toast.error(`Минимальная сумма вывода 1000 руб.\n
Максимальная сумма вывода 15 000 руб./день.\n`, 'Произошла ошибка', {
          position: "topCenter"
        });
      }
      if(!res.statusCode){
        this.$toast.success(`Вывод денежных средств на карту осуществляется в течении 72 часов после формирования заявки на выплату! С учетом комиссий сумма к зачислению - ${res.truepayoutSum}₽`, 'Запрос выполнен', {
          position: "topCenter"
        });
      }

    },
  },
  async mounted() {
    this.user = await Auth();
    const ctx = this;
    if (!this.user.id) {
      document.location.href = "/";
    }

    const cardlist = await Api.users.getCard(localStorage.getItem("token"));
    this.cardlist = cardlist;
    this.users = await Api.users.dobCard(localStorage.getItem("token"));
  },
};
</script>
<style lang="css">
.mmmaincont {
  min-height: 100vh;
  padding: 2%;
  background-color: #e9e9e9;
}
.glav {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.card__content {
  margin-top: 40px;
}
.info {
  color: #fe6637;
  font-size: 40px;
  text-align: left;
  cursor: pointer;
  transition: all 0.6s;
}
.info:hover {
  color: #da3300;
  font-size: 42px;
}
.info:active {
  transform: translateY(-10px);
  transform: translateY(10px);
}
.lol {
  margin-bottom: 15px;
}

.contts {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cardds {
  background-color: white;
  margin-bottom: 20px;
  display: grid;
  border-radius: 13px;
  grid-template-columns: 1fr 5fr;
  width: 70%;
  padding: 0 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 10px 16px rgba(0, 0, 0, 0.2);
  column-gap: 10px;
}
.img_contts > img {
  width: 100%;
}
.tittlems {
  height: 100%;
  display: flex;
  align-items: center;
}
.te {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}
.te > p {
  font-size: 18px;
  margin-bottom: -3px;
  text-align: left;
}
.ds {
  flex: 1 100%;
}
.righttps {
  display: flex;
  justify-content: space-between;
}
.buttns {
  display: flex;
  align-items: flex-end;
  padding-bottom: 14px;
}
.buttns > button {
  flex: 1 100%;
  width: 100px;
  padding: 4px 4px;
  margin-right: 6px;
  background-color: white;
  border: solid 1px rgb(156, 156, 156);
  color: rgb(99, 99, 99);
  border-radius: 10px;
}
.lixfrG {
  display: none;
}

.formbgcard {
  min-width: 280px;
  max-width: 460px;
  margin: 0px auto;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}
.form-innercard {
  padding: 20px;
}

.creditcard {
  display: flex;
  flex-direction: row;
  justify-content: left;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding-bottom: 30px;
}
.numbercard {
  padding-top: 24px;
}
.fieldnumber > input {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}
.fieldnumber {
  text-align: left;
}
.fieldnumber span{
  margin-bottom: 26px;
  font-family: 'tahoma';
  font-size: 14px;
  display: inline-block;
}

.fieldnumber > label {
  font-size: 15px;
  line-height: 40px;
  color: #0a2540b3;
}
.fieldcard {
  margin-top: 15px;
}
.cardssub {
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: #fe6637;
  text-align: center;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
.fieldests {
  text-align: center;
  padding-top: 15px;
}
.fieldests > span {
  font-size: 13px;
  color: grey;
  color: #0a254080;
}

@media only screen and (max-width: 600px) {
  .glav {
    display: grid;
    grid-template-columns: 1fr;
  }
  .cardds {
    width: 90%;
  }
  .aaaaa {
    overflow-x: scroll;
  }
  .formbg-outercard {
    margin-bottom: 40px;
  }
}
</style>
