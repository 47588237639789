<template>
    <div class="infcont">
        <h1>Соглашение с подпиской</h1>
        <p>При покупке подписки раз в месяц будет сниматься сумма, которая обозначена в цене на кнопке(например: 299 р в месяц)</p>
        <p>При желании отмены подписки вам надо перейти на <a href="/mysubs"> страницу</a>, где будет находиться кнопка "отписаться". При возникновении вопросов можно написать в выпадающую меню jivo-сайта или на почту support@roobo.pro</p>
<br>
<p></p>
<h2>Оферта регулярных платежей</h2><br>
Соглашение<br>
о предоставлении физическим лицам возможности оплаты услуг Индивидуального предпринимателя Яковлева Михаила Геннадьевича посредством регулярных автоматических переводов денежных средств</p>

<p>Индивидуальный предприниматель Яковлев Михаил Геннадьевич, действующий на основании Выписки из Единого государственного реестра индивидуальных предпринимателей, ГРНИП: 321602700021753 от 13.09.2021 именуемый в дальнейшем Поставщик, предлагает Пользователям воспользоваться Сервисом «Рекуррентные списания» и заключить настоящее соглашение (далее – Соглашение), являющееся дополнением к Договору и неотъемлемой частью Договора, об определении порядка расчетов по Договору.<br>
Пользователь безусловно соглашается с настоящим Соглашением пользования сервисом путем совершения конклюдентных действий по подключению Сервиса<br>
«Рекуррентные списания» в момент принятия Пользователем настоящего соглашения пользования сервисом и выполнения действий по активации Сервиса «Рекуррентные списания».</p>

<p>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ<br>
Сервис «Рекуррентные списания» – сервис по осуществлению регулярных автоматических переводов денежных средств с Банковской карты Держателя в пользу Поставщика за предоставляемые Поставщиком услуги по Договору на основании заранее данного акцепта Держателя карты, в соответствии с тарифами и опциями по Договору.<br>
Договор – двустороннее соглашение, заключенное между Пользователем и Поставщиком по условиям которого, Поставщик обязуется предоставить Пользователю Услугу, а последний обязуется оплатить их.<br>
Услуга – предоставление Поставщиком услуги из ассортимента сайта https://roobo.pro/ (сайт магазина)<br>
Пользователь – физическое лицо, заключившее Договор с Поставщиком.<br>
Банк – кредитная организация – АО «Тинькофф Банк», БИК: 044525974, К/с:30101810145250000974.<br>
Запрос – информационное сообщение, направляемое Банком в Банк-эмитент на перевод денежных средств с Банковской карты в пользу Поставщика.<br>
Банк-эмитент – кредитная организация, осуществляющая выпуск Банковских карт, а также расчеты по операциям, совершаемым с использованием Банковских карт.<br>
Процессинговый центр – АО «Тинькофф Банк», БИК: 044525974, К/с:30101810145250000974.<br>
Держатель карты – физическое лицо, дающее распоряжение Банку-эмитенту на перевод денежных средств с Банковской карты от имени Пользователя в пользу Поставщика с помощью Сервиса «Рекуррентные списания».<br>
Банковская карта – расчетная или кредитная карта, эмитентом которой является Банк-эмитент, являющаяся инструментом безналичных расчетов, предназначенная для совершения Держателем карты операций с денежными средствами, находящимися на банковских счетах Держателя карты в Банке-эмитенте, или с денежными средствами, предоставленными Банком-эмитентом в кредит Держателю карты в соответствии с законодательством Российской Федерации, а также договором банковского счета, или в пределах установленного лимита, в соответствии с условиями кредитного договора между Банком-эмитентом и Держателем карты, при условии, что такая расчетная или кредитная карта может быть использована Держателем карты для оплаты Услуг Поставщика. Оплата Услуг Поставщика в соответствии с настоящим Соглашением возможна Банковскими картами платежных систем VISA International, MasterCard International, зарегистрированными в соответствии с действующим законодательством Российской Федерации. При этом возможность использования конкретных видов карт VISA International и MasterCard International устанавливает Банк-эмитент.<br>
Акцепт Пользователя – безусловное соглашение Пользователя с условиями настоящего Соглашения.<br>
Личный кабинет – раздел Сайта Поставщика, защищенный системой контроля доступа, обеспечивающий Пользователю интерфейс для взаимодействия с Поставщиком по Договору.<br>
Сайт Поставщика – сайт, размещенный в сети Интернет по адресу: https://roobo.pro/</p>

<p>Описание Сервиса «Рекуррентные списания» и способ его активации<br>
Сервис «Рекуррентные списания» позволяет Пользователю оплачивать Услуги Поставщика по Договору, путем регулярных автоматических переводов денежных средств в пользу Поставщика с Банковских карт. Активация (подключение) Сервиса «Рекуррентные списания» предполагает выполнение следующих действий:<br>
В Личном кабинете на Сайте Поставщика, Пользователь выбирает раздел «Платежи», подраздел «Сделать платеж»; Пользователь выбирает оплату очередного платежа и ставит флажок напротив пункта «Подключить Автоматический платеж», соглашаясь с настоящим Соглашением пользования Сервисом «Рекуррентные списания» и нажимает кнопку «Оплатить». Сервис «Рекуррентные списания» подключается только для Банковской карты, зарегистрированной Держателем карты.<br>
Регистрация Банковской карты: Держатель карты вводит на WEB-странице ввода платежных данных данные своей Банковской карты.</p>

<p>Действие Сервиса «Рекуррентные списания»<br>
После подключения Сервиса «Рекуррентные списания», с Банковской карты будут осуществляться переводы денежных средств в сроки и размерах, необходимых для надлежащего исполнения обязанности Пользователя по Договору.<br>
 Непосредственное списание денежных средств осуществляет Банк-эмитент на основании заранее данного согласия Пользователя на подобное списание в сумме и в срок, указанные в Договоре. Запрос на перевод денежных средств с указанием суммы перевода в Банк-эмитент передается Банком и Процессинговым центром в рамках заключенных с Поставщиком соглашений.<br>
В один момент времени, у Пользователя может быть активирован (подключен) Сервис «Рекуррентные списания» только по одной Банковской карте платежной системы Visa (Visa Inc.), MasterCard или Maestro (MasterCard International Inc.).<br>
Поставщик не хранит и не обрабатывает данные банковских карт Пользователей, а также иные персональные данные Пользователей, обеспечивая лишь запросы в Процессинговый центр и Банк для повторного проведения операции по банковской карте Пользователя.<br>
Поставщик не гарантирует возможность проведения операций по Банковской карте.<br>
Пользователь гарантирует, что он является Держателем банковской карты, осознанно, корректно и полностью вводит все требуемые реквизиты Банковской карты при активации (подключении) Сервиса «Рекуррентные списания».<br>
Совершение действий, предусмотренных п. 1.1. Соглашения признается аналогом собственноручной подписи Пользователя.<br>
Подключение Сервиса «Рекуррентные списания», в порядке, предусмотренном настоящим Соглашением пользования сервисом, осуществляется только при наличии<br>
технической возможности Поставщика, Процессингового центра, Банка, Банка-эмитента. Поставщик, Процессинговый центр, Банк не несут ответственность за невозможность подключения Сервиса «Рекуррентные списания».<br>
Поставщик имеет право вносить изменения в настоящее Соглашение пользования сервиса, заранее уведомив об этом Пользователя в письменной форме либо иным доступным способом, в том числе путем сообщения на электронную почту, указанную в Договоре Пользователем, либо телефонограммой, либо размещением информации на Веб-Сайте Поставщика.</p>

<p>Отключение Сервиса «Рекуррентные списания»<br>
Отключение (отказ) Пользователя от Сервиса «Рекуррентные списания» происходит:<br>
А) В «Личном кабинете» Пользователь выбирает раздел «Платежи», подраздел «Сделать платеж»;<br>
Б) Нажимает кнопку «Отключить сервис «Автоматический платеж».<br>
При этом денежные средства, переведенные с Банковской карты до отключения (отказа) Пользователя от Сервиса «Рекуррентные списания» за Услуги, Пользователю не возвращаются.<br>
 В случае недостаточности денежных средств на счете Банковской карты для осуществления перевода Поставщик имеет право повторять запрос на списание с Банковской карты денежных средств.</p>

<p>Ограничения при пользовании Сервисом «Рекуррентные списания»<br>
С одной Банковской карты может быть проведено не более 5 (пяти) переводов в сутки.<br>
С одной Банковской карты может быть осуществлен перевод не более, чем на 15000 рублей за одну операцию.<br>
С одной Банковской карты может быть осуществлено переводов не более, чем на 50000 рублей в сутки.<br>
Переход на страницу «Сделать платеж» возможен, лишь в случае если у Пользователя подключено соединение к сети Интернет, независимо от текущего состояния счета.</p>

<p>Ответственность сторон<br>
Поставщик, Процессинговый центр, Банк не несет ответственности за неработоспособность и/или временную неработоспособность Сервиса «Рекуррентные списания». Пользователь самостоятельно контролирует исполнение своей обязанности по оплате по Договору. При неработоспособности и/или временной неработоспособности Сервиса «Рекуррентные списания» Пользователь обязан использовать иные способы исполнения обязанностей по Договору.<br>
Споры сторон, возникшие в связи с выполнением условий настоящего Соглашения, разрешаются в ходе направления претензий.<br>
В случае недостижения Сторонами взаимного согласия, споры между ними разрешаются в соответствии с условиями Договора и Оферты.</p>

<p>Прочие условия<br>
Выполняя действия по подключению Сервиса «Рекуррентные списания»:<br>
А) Пользователь безусловно соглашается со всеми условиями настоящего Соглашения;<br>
Б) Пользователь дает заранее данный акцепт на списание денежных средств<br>
Поставщиком с Банковской карты на условиях настоящего Соглашения;<br>
В) Пользователь предоставляет право Процессинговому Центру, Банку хранить, собирать, обрабатывать свои персональные данные (Фамилию, Имя, Отчество, серию и номер паспорта, место и дату рождения, номер расчетного счета и Банковской карты) с целью реализации настоящего Соглашения;<br>
Г) Пользователь дает согласие на оплату Услуг в соответствии с условиями Соглашения.<br>
Поставщик имеет право отключить и/или ограничить Пользователя от Сервиса «Рекуррентные списания».</p>

<p>ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ И ПОСТАВЩИКА, ВЫТЕКАЮЩИЕ ИЗ НАСТОЯЩЕЙ ОФЕРТЫ, НЕ МОГУТ БЫТЬ ПЕРЕДАНЫ ТРЕТЬИМ ЛИЦАМ БЕЗ ПИСЬМЕННОГО СОГЛАСИЯ ДРУГОЙ СТОРОНЫ.</p>

<p>Я, ПОЛЬЗОВАТЕЛЬ, ОЗНАКОМЛЕН И СОГЛАСЕН С НАСТОЯЩИМ СОГЛАШЕНИЕМ ИСПОЛЬЗОВАНИЯ СЕРВИСА «РЕКУРРЕНТНЫЕ ПЛАТЕЖИ», С АВТОМАТИЧЕСКИМ СПИСАНИЕМ ДЕНЕЖНЫХ СРЕДСТВ С БАНКОВСКОЙ КАРТЫ ЗА УСЛУГИ ПОСТАВЩИКА ПО ДОГОВОРУ.<br>
</p>
    </div>
</template>
<script>
export default {
    name: "fzf",
}
</script>
<style lang="scss">
.infcont{
    min-height: 100vh;
    padding: 1%;
    text-align: left;
}
</style>
