<template>
  <div>
    <HelloWorld :user="user" />
    <div class="maincont">
      <div class="up_redirect_table">
        <h2>Категория «{{categoryName}}»</h2>
        <div class="up_redirect_table_buttons" v-if="statusCategory">
          <a class="up_redirect_table_buttons_blue" v-on:click="editCategory" >Редактировать</a>
          <a class="up_redirect_table_buttons_red" v-on:click="showModalDelete" >Удалить</a>
        </div>
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <b-table
        id="my-table"
        :fields="fields"
        :items="users"
        :per-page="perPage"
        :current-page="currentPage"
        small
        class="table_redirect"
      >
        <template #cell(ID)="data">
          <div class="redirect_clicks_up">{{ data.item.id }}</div>
        </template>
        <template #cell(Пометка)="data">
          <div v-for="item in data.item.meaning" :key="item">
            <a href="#">
              <div class="block_icon_redirect">
                <img :src="getImgUrl(item +'.svg')" alt="" class="img_redirect_table">
              </div>
            </a>
          </div>
        </template>
        <template #cell(Название)="data">
          <div class="name_table_redirect">
            <p class="item_name_redirect">{{ data.item.name}}</p>
            <p class="item_link_redirect">{{data.item.link}}</p>
          </div>
        </template>
        <template #cell(Ссылка)="data">
         <div class="name_table_redirect_up">
           <div class="name_table_redirect">
             <p class="item_link_solid_left">Редирект:</p> <p class="item_link_solid" style="cursor: pointer"  @click="copyLinkinputRedirectTable(data.item.id)">{{ data.item.linkSolid }} <b-icon
             style="font-size: 25px; color: #fe6637"
             class="letbut"
             icon="link45deg"
             title="Title"
             variant="primary"
           ></b-icon></p>
             <input type="text" style="opacity: 0;"  :id="data.item.id" :value="data.item.linkSolid">
           </div>
         </div>
        </template>
        <template #cell(Показов)="data">
          <div class="redirect_clicks_up_pokaz">
            <div class="redirect_clicks">Клики {{ data.item.clicks.all }} / {{data.item.clicks.unical}}</div>
            <div class="redirect_clicks_green">Подписки {{data.item.buying.all.sub}} / {{data.item.buying.oplat.sub}}</div>
            <div class="redirect_clicks_green">Товары {{data.item.buying.all.tovar}} / {{data.item.buying.oplat.tovar}}</div>
          </div>
        </template>
        <template #cell(Создан)="data" >
          <div class="redirect_clicks_up">{{ new Date(data.item.createdAt).toLocaleString('ru-RU').replace(',', '').slice(0, -3) }}</div>
        </template>
        <template #cell(Действие)="data">
          <div class="redirect_clicks_up">
            <div class="buttons_grey_redirect">
              <a :href="'https://'+user.login +'.roobo.pro/redirect/'+data.item.id+'/analytics'" class="buttons_grey_redirect_1">
                <img src="../assets/icons8-аналитика-30.png" alt="">
              </a>
              <div class="white_line_redirect"></div>
              <a :href="'https://'+user.login +'.roobo.pro/redirect/'+data.item.id+'/edit'" class="buttons_grey_redirect_2">
                <img src="../assets/icons8-редактировать.svg" alt="">
              </a>
              <div class="white_line_redirect"></div>
              <a class="buttons_grey_redirect_3" v-on:click="showModalDelete2(data.item.id)" >
                <img src="../assets/icons8-полная-корзина-50.png" alt="">
              </a>
            </div>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>
    <b-modal id="modal-redirect">
      <template #modal-header="">
        <h5>Категории</h5>
      </template>
      <template #default="">
        <h5>Название</h5>
        <input type="text" class="modal_body_input" placeholder="Название" v-model="categoryName">
        <p class="wwarning">
          {{ err }}
        </p>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          style="margin-right: 10px"
          variant="primary"
          v-on:click="sendInsult()"
        >Сохранить</b-button
        >
        <b-button variant="secondary" @click="cancel()"> Закрыть </b-button>
      </template>
    </b-modal>
    <b-modal id="modal-redirect-delete">
      <template #modal-header="">
        <h5>Категории</h5>
      </template>
      <template #default="">
        <h5>Вы уверены, что хотите удалить категорию {{categoryName}}</h5>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          style="margin-right: 10px"
          variant="primary"
          v-on:click="deleteCategory()"
        >Удалить</b-button
        >
        <b-button variant="secondary" @click="cancel()"> Закрыть </b-button>
      </template>
    </b-modal>
    <b-modal id="modal-redirect-delete_redirect">
      <template #modal-header="">
        <h5>Удаление редиректа</h5>
      </template>
      <template #default="">
        <h5>Вы уверены, что хотите удалить редирект</h5>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button
          style="margin-right: 10px"
          variant="primary"
          v-on:click="deleteRedirect()"
        >Удалить</b-button
        >
        <b-button variant="secondary" @click="cancel()"> Закрыть </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import HelloWorld from "@/components/HelloWorld.vue";
import { Auth } from "../auth";
import Api from "../api";
export default {
  methods: {
    copyLinkinputRedirectTable(id) {
      // this.copyLinkToRedirect = data.item.linkSolid
      let textLinkInputRedirectTable = document.getElementById(id);
      textLinkInputRedirectTable.select()
      document.execCommand('copy');
      alert("Текст успешно скопирован")
      // this.copyLinkToRedirect = ""
    },
    smth(){
      console.log('sdalkhlas')
    },
    getImgUrl(pic) {
      return require('../assets/'+pic)
    },
    editCategory() {
      this.$bvModal.show("modal-redirect");
    },
    async sendInsult() {
      const data = {
        name: this.categoryName
      }
      const result3 = await Api.users.refactorCategory(data, localStorage.getItem("token"), this.category.id)
      if (result3.message) {
        this.err = result3.message
      }
      if (!result3.message) {
        this.$bvModal.hide("modal-redirect");
      }
    },
    async deleteCategory() {
      const resultDelete = await Api.users.deleteCategoryId(localStorage.getItem("token"), this.category.id)
      if (!resultDelete.message) {
        this.$bvModal.hide("modal-redirect-delete");
        window.location.href = "/category/all"
      }
    },
    async deleteRedirect() {
      const resultDelete2 = await Api.users.deleteRedirectId(localStorage.getItem("token"), this.deleteID)
      if (!resultDelete2.message) {
        this.$bvModal.hide("modal-redirect-delete2");
        window.location.href = "/category/all"
      }
    },
    showModalDelete() {
      this.$bvModal.show("modal-redirect-delete")
    },
    showModalDelete2(id) {
      this.deleteID = id
      this.$bvModal.show("modal-redirect-delete_redirect")
      console.log("вызов модального окна 2")
    }
  },
  name: "redirectTable",
  components: {
    HelloWorld,
  },
  async mounted() {

    this.user = await Auth();
    const result = await Api.users.redirectGetAllCategory(localStorage.getItem("token"), this.$route.params.type)
    console.log(localStorage.getItem("token"))
    this.users = result
    console.log(result)
    if(this.$route.params.type != "all" && this.$route.params.type != "none" ) {
      const result2 = await Api.users.getCategoryById(localStorage.getItem("token"), this.$route.params.type)
      this.statusCategory = true
      this.categoryName = result2.name
      this.category = result2
    }
    if (this.$route.params.type == "all") {
      this.categoryName = "Все категории"
    }
    if (this.$route.params.type == "none") {
      this.categoryName = "Без категорий"
    }
  },
  data() {
    return {
      copyLinkToRedirect: "",
      userw: false,
      fields: [
        "ID",
        "Пометка",
        "Название",
        "Ссылка",
        "Показов",
        "Создан",
        "Действие",
      ],
      users: {},
      perPage: 50,
      currentPage: 1,
      category: {},
      categoryName: "",
      statusCategory: false,
      err: "",
      deleteID: 0,
    };
  },
  computed: {
    rows() {
      return this.users.length;
    },
  },
}
</script>
<style scoped>
.letbut {
  font-size: 24px;
  float: right;
  color: #fe6637 !important;
}
.dollar_img_clicks {
  height: 40px;
}
th > div {
  padding: 15px;
}
.redirect_clicks_up {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
.redirect_clicks_up_pokaz {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  flex-direction: column;
}
.block_icon_redirect {
  display: flex;
  align-items: center;
  justify-content: center;
}
td {
  vertical-align: middle;
}
.img_redirect_table {
  width: 35px;
}
.name_table_redirect_up {
  display: flex;
  width: 100%;
  height: 100%;
}
.item_link_solid {
  color: #ffbc40;
  text-align: left;
}
.item_link_solid_left {
  text-align: left;
  margin-bottom: 0px;
}
.redirect_clicks {
  background-color: #660066;
  border-radius: 20px;
  color: #ffffff;
  display: inline-block;
  padding: 5px 10px 5px 10px;
}
.redirect_clicks_green {
  margin-top: 15px;
  background-color: #00ff00;
  border-radius: 20px;
  color: #ffffff;
  display: inline-block;
  padding: 5px 10px 5px 10px;
}
.item_link_redirect {
  font-size: 14px;
  color: gray;
  display: inline-block;
  max-width: 300px;
  word-wrap: break-word;
  text-align: left;
}
.item_name_redirect {
  font-weight: bold;
  font-size: 20px;
  display: inline-block;
  width: 100%;
  height: 100%;
  max-width: 300px;
  word-wrap: break-word;
  text-align: left;
}
.up_redirect_table > h2 {
  color: #a000a0;
}
.table_redirect {
  background-color: #ffffff;
}
.name_table_redirect{
  max-width: 300px;
  word-wrap: break-word;
  display: flex;
  width: 100%;
  text-align: left;
  min-height: 200px;
  flex-direction: column;
  justify-content: center;
}
.buttons_grey_redirect {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 6px solid #e9e9e9;
  width: 150px;
  height: 60px;
}
.buttons_grey_redirect_1 > img, .buttons_grey_redirect_2 > img, .buttons_grey_redirect_3 > img {
  width: 25px;
}
.buttons_grey_redirect_1, .buttons_grey_redirect_2, .buttons_grey_redirect_3 {
  display: flex;
  align-items: center;
}
.buttons_grey_redirect_3 {
  margin-right: 3px;
}
.white_line_redirect {
  height: 60px;
  border-left: 6px solid #e9e9e9;
}
.buttons_grey_redirect_1 > img {
  margin-left: 6px;
}
.maincont {
  padding: 3%;
  overflow: scroll;
}
.up_redirect_table {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 93px;
  margin-bottom: 15px;
  padding: 0 20px;
}
.up_redirect_table_buttons {
  display: flex;
  align-items: center;
}
.up_redirect_table_buttons_blue {
  width: 200px;
  height: 31px;
  text-decoration: none;
  color: #0d6efd;
  border: 3px solid #0d6efd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.up_redirect_table_buttons_red{
  width: 200px;
  height: 31px;
  text-decoration: none;
  color: #bb2d3b;
  border: 3px solid #bb2d3b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
</style>
