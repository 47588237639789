<template>
    <div class="infcont">
<p>Оферта об условиях предоставления сервиса «Безопасная сделка»</p>

<p>Текст настоящей Оферты об условиях предоставления сервиса «Безопасная сделка» (далее – Оферта) является официальным публичным предложением, адресованным физическим лицам, являющимся Пользователями Сайта https://roobo.pro.<br>
Акцепт условий настоящей Оферты осуществляется в форме конклюдентных действий, предусмотренных Офертой, и означает безоговорочное принятие физическим лицом всех условий настоящей Оферты без каких-либо изъятий или ограничений на условиях присоединения согласно ст. 437 и ст. 428 Гражданского кодекса Российской Федерации.<br>
Акцепт условий настоящей оферты подтверждает факт заключения Договора об использовании сервиса «Безопасная сделка» (далее – Договор).</p>

<p>1. ПРЕДМЕТ ОФЕРТЫ<br>
1.2. В рамках Сервиса Сайт оказывает услуги по предоставлению Пользователям функциональности Сайта, обеспечивающей возможность оплаты Оферентом и получения Акцептантом Стоимости Работ в безналичном порядке с использованием Карты (ее реквизитов), а также услуги по обмену информацией между Оферентом и Акцептантом с целью заключения и исполнения Сделки, совершенной в рамках использования Сервиса. <br>
1.3. Сайт является площадкой, позволяющей Оферентам самостоятельно на свой страх и риск размещать предложения, адресованные неопределенному кругу лиц на совершение сделки в отношении Работ, а Акцептантам принимать на свое усмотрение и под свою ответственность Заказы, размещенные на Сайте Оферентами, заключая соответствующую сделку с Оферентом.<br>
1.4. При заключении Сделки Акцептант вступает в прямые договорные отношения с Оферентом, у которого Акцептант приобретает услуги по выполнению Работ. Общество не является стороной по Сделке, организатором Сделки, Оферентом, Акцептантом, посредником, агентом или представителем какого-либо Пользователя и/или иным заинтересованным лицом в отношении предлагаемой/заключаемой между Пользователями Сделки. Все совершаемые Сделки между Пользователями заключаются и исполняются без прямого или косвенного участия Сайта.</p>

<p>2. СОГЛАСИЕ С ОФЕРТОЙ<br>
2.1. Настоящая Оферта считается акцептованной Пользователем в момент совершения Пользователем соответствующих действий:<br>
2.1.1. В отношении Оферента: формирование и размещение Оферентом с использованием функционала Сайта Заказа на Сайте;<br>
2.1.2. В отношении Акцептанта: подтверждения акцепта Заказа путем нажатия кнопки «Откликнуться на заказ» на Сайте.<br>
2.2. Соглашаясь с условиями настоящей Оферты, Пользователь гарантирует, что он полностью и внимательно ознакомился с условиями настоящей Оферты.<br>
2.3. Фиксация факта акцепта настоящей Оферты осуществляется Администрацией Сайта в электронном виде и хранится в аппаратно-программном комплексе Сайта. Выписки из аппаратно-программного комплекса Сайта могут использоваться в качестве доказательств при рассмотрении споров, в том числе в судебном порядке.<br>
2.4. Осуществляя доступ к Сервису и заключая договоры, путем акцепта настоящей Оферты, Пользователь гарантирует, что обладает всеми правами и полномочиями, необходимыми для заключения и исполнения таких договоров, в том числе является совершеннолетним и полностью дееспособным лицом, либо несовершеннолетним лицом, объявленным по решению уполномоченного органа полностью дееспособным (эмансипация) либо несовершеннолетним лицом, достигшим четырнадцати лет и получившим письменное разрешение в требуемой законом форме от своих родителей или иных законных представителей на заключение договоров. Администрация Сайта вправе в любое время потребовать от Пользователя предоставление информации и документов, подтверждающих права и полномочия, как указано выше.<br>
2.5. Администрация Сайта вправе вносить в Оферту и приложения к ней изменения, которые вступают в силу с момента публикации на Сайте, и не требуют какого–либо предварительного уведомления (одобрения, согласия) Пользователей. Оферта в новой редакции действует как для новых Пользователей, так и для Пользователей, принявших условия Оферты до внесения изменений.</p>

<p>3. ПОРЯДОК ПРЕДОСТАВЛЕНИЯ СЕРВИСА<br>
3.1. Оплата по Сделкам<br>
3.1.1. 3.1.1. Размещение Заказа осуществляется при помощи программно-технических средств Сайта через Личный кабинет. Заказ считается размещенным Оферентом с момента его опубликования на Сайте. Заказ должен отвечать требованиям, предусмотренным Соглашением и не может противоречат требованиям законодательства Российской Федерации.<br>
3.1.2. Условия Сделки, заключаемой между Оферентом и Акцептантом, указываются Оферентом в Заказе.<br>
3.1.3. До заключения Сделки с использованием Сервиса Акцептант обязуется внимательно ознакомиться со всеми условиями Сделки и требованиями к работам, указанными в описании Заказа. Акцептант вправе обратиться к Оференту с целью уточнения условий выполнения Заказа путем использования интерфейса Сайта. Принятие Заказа Акцептантом означает согласие Акцептанта со всеми условиями, указанными в Заказе.<br>
3.1.4. При использовании Сервиса Оферент вправе привязать к его Личному кабинету Карту - для более удобного и быстрого формирования Заказа, для осуществления возврата/частичного возврата денежных средств в случаях, предусмотренных в настоящей Оферте. В случае привязки Карты Банк осуществляет сохранение реквизитов (привязку) Карты Оферента, с целью предоставления возможности проведения повторных операций с данной Картой в рамках Сервиса без повторного ввода ее реквизитов. При этом Администрация Сайта не осуществляет хранение данных Карт пользователей. Также Оферент вправе осуществлять оплату Заказа путем ввода данных своей Карты каждый раз при совершении Сделки (без привязки платежной карты).<br>
3.1.5. При завершении формирования Заказа Оферент в интерфейсе Сайта с использованием Сервиса выводится Платежная страница Банка для ввода данных платежной карты Оферента, если она не была привязана ранее, и подтверждения оплаты по Сделке. Совершая указанные в настоящем пункте действия, Оферент дает Поручение Банку на списание денежных средств в размере Стоимости Работ (Услуг) с Карты Оферента с целью осуществления последующих расчетов с Акцептантом.<br>
3.1.6. При размещении Заказа с использованием Сервиса Оферент привязывает к своему Личному кабинету платежную карту путем ввода данных Карты на соответствующей Платежной странице Банка.<br>
3.1.7. В случае привязки Карты Банк осуществляет сохранение реквизитов (привязку) Карты Пользователя, с целью предоставления возможности проведения повторных операций с данной Картой в рамках Сервиса без повторного ввода ее реквизитов. Оферент также вправе ввести данные своей Карты без ее привязки.<br>
3.1.8. При наличии на счете Карты денежной суммы, равной Стоимости Работ (Услуг), комиссии Сайта за использование Сервиса, а также комиссий Банка и Сайта за предоставление Сервиса, происходит списание денежных средств с Карты Оферента и их зачисление на счет Банка на период осуществления Работ (Услуг).<br>
3.1.9. По факту получения от Администрации Сайта информации о наступлении условий по оплате Работ (Услуг), согласно условиям Сделки, в частности информации о принятии Работ (Услуг) и исключительных прав Оферентом от Акцептанта, Банк осуществляет зачисление денежных средств на Карту Акцептанта.<br>
3.1.10. В случае продления сроков выполнения Заказа Пользователями с использованием соответствующего функционала Сайта, Администрация Сайта уведомляет об этом Банк, который продляет сроки удержания средств Оферента на счете.<br>
3.1.11. В случае изменения Стоимости Работ (Услуг) по соглашению Пользователей с использованием функционала Сайта Администрация Сайта передает Банку информацию о внесении соответствующих изменений и Банк осуществляет дополнительное списание денежных средств с Карты Оферента и их зачисление на счет Банка на период осуществления Работ либо возврат денежных средств Оференту в соответствии с условиями настоящей Оферты.<br>
3.1.12. Оферент и Акцептант самостоятельно уплачивают все необходимые налоги, сборы и взносы, подлежащие уплате в связи с заключением Сделки, в соответствии с применимым правом и самостоятельно несут соответствующие риски и ответственность в случае их неуплаты.</p>

<p>3.2. Возврат Банком денежных средств<br>
3.2.1. Банк производит возврат денежных средств Оференту в следующих случаях:<br>
3.2.1.1. При получении от Администрации Сайта информации об отказе Оферента от Акцептанта (возврат в случае отказа от Акцептанта);<br>
3.2.1.2. При получении от Администрации сайта сообщения о пересмотре Стоимости Работ в меньшую сторону (возврат в случае снижения Стоимости Работ);<br>
3.2.1.3. При неполучении от Администрации Сайта информации о наступлении условий по оплате Работ (Услуг) в срок, указанный в Заказе для их выполнения (возврат по истечении срока осуществления Работ (Услуг);<br>
3.2.1.4. В случае получения от Администрации Сайта сообщения о принятии Арбитражем решения в пользу Оферента при рассмотрении спора Пользователей (возврат по решению Арбитража);<br>
3.2.1.5. В случае принятии Банком соответствующего решения по результатам рассмотрения обращения Пользователя (возврат по результатам рассмотрения обращений).<br>
3.2.2. Банк производит возврат денежных средств в размере стоимости Работ за вычетом комиссии Банка по реквизитам Карты Оферента. Возврат комиссии Сайта в случае наступления условия предусмотренных настоящей Офертой производится Банком по получении соответствующего распоряжения от Администрации Сайта. Пользователь самостоятельно несет любые расходы, связанные с осуществлением возврата денежных средств, включая расходы на комиссии Банка.</p>

<p>3.2.3. Возврат в случае отказа от Исполнителя<br>
3.2.3.1. Оферент вправе отказаться от Акцептанта, в соответствии с условиями настоящего пользовательского Соглашения.<br>
3.2.3.2. В случае получения от Администрации Сайта соответствующей информации об отказе Оферента от Акцептанта Банк производит возврат денежных средств в размере стоимости Работ (Услуг) и комиссии Сайта за вычетом комиссии Банка по реквизитам Карты Оферента.</p>

<p>3.2.4. Возврат в случае снижения Стоимости Работ (Услуг)<br>
3.2.4.1. Стороны вправе пересмотреть Стоимость Работ (Услуг) с использованием функционала Сайта в порядке, предусмотренном настоящим Пользовательским Соглашением.<br>
3.2.4.2. В случае снижения Стоимости Работ (Услуг) по результатам пересмотра Стоимости Работ (Услуг) Администрация Сайта передает соответствующую информацию Банку, который производит возврат денежных средств в размере разницы Стоимости Работ (Услуг) и комиссии Общества за вычетом комиссии Банка по реквизитам Карты Оферента.</p>

<p>3.2.5. Возврат по истечении срока осуществления Работ (Услуг)<br>
3.2.5.1. В случае неполучения от Администрации Сайта соответствующей информации по окончании срока осуществления Работ (Услуг), указанного в Заказе, Банк осуществляет возврат денежных средств в размере Стоимости Работ (Услуг) и комиссии Сайта за вычетом комиссии Банка по реквизитам Карты Оферента.</p>

<p>3.2.6. Возврат по решению Арбитража<br>
3.2.6.1. В случае наличия претензий Оферента в отношении Работ (Услуг) Акцептантом он вправе обратиться с жалобой в Арбитраж в соответствии с правилами, предусмотренными настоящим Пользовательским Соглашением.<br>
3.2.6.2. По результатам рассмотрения жалобы Пользователя Арбитраж принимает решение на основании требований в Заказе, вложения к Заказу, качества Работы (Услуги) Акцептанта и истории доработок.<br>
3.2.6.3. В случае удовлетворения жалобы Оферента Арбитражем Администрация Сайта сообщает об этом Банку, который производит возврат денежных средств в размере Стоимости Работ (Услуг) и комиссии Сайта за вычетом комиссии Банка по реквизитам Карты Оферента.<br>
3.2.6.4. Пользователи принимают и соглашаются, что решение, принятое Арбитражем по результатам рассмотрения жалобы, может служить основанием для осуществления расчетов между Пользователями в Сервисе (возврата денежных средств Оференту или перевода денежных средств Акцептанту).</p>

<p>3.2.7. Возврат по результатам рассмотрения обращений<br>
3.2.7.1. Пользователь вправе обратиться к Администрации Сайта с целью разрешения претензий, связанных с использованием Сервиса, путем отправления электронного письма на электронный адрес: support@roobo.pro.<br>
3.2.7.2. Обращение должно содержать описание проблемы, документы, подтверждающие позицию Пользователя, документы, предусмотренные условиями настоящей Оферты, а также иные необходимые документы.<br>
3.2.7.3. Администрация Сайта вправе запросить у Пользователя иные документы и сведения, необходимые для рассмотрения обращения (в т.ч., без ограничений, заключение независимой экспертизы, если таковое необходимо для рассмотрения обращения).<br>
3.2.7.4. После получения всех необходимых документов и сведений Администрация Сайта в течение 3 (Трех) рабочих дней передает информацию о наличии претензий в отношении Сервиса Банку, который рассматривает обращение Пользователей и принимает решение в отношении претензии на основании предоставленных документов и сведений.<br>
3.2.7.5. По результатам рассмотрения обращения Пользователя Банк принимает решение на основании переданных ему документов и доводов и осуществляет соответствующие действия, в том числе возврат денежных средств Пользователю, если это соответствует решению, принятому Банком.</p>

<p>4. ОПЛАТА СЕРВИСА<br>
4.1. Оферент и Акцептант понимают и соглашаются, что при переводах денежных средств на карту и с карты могут взиматься дополнительные комиссии.<br>
4.2. Банк не несет ответственности за комиссии, взимаемые банком-получателем и/или банком-отправителем денежных средств.<br>
4.3. За оказание услуг в рамках Сервиса Банк и Администрация Сайта имеют право взимать с Клиента комиссию.<br>
4.3.1. Комиссия Банка взимается Банком с Оферента согласно Тарифам.<br>
4.3.2. Комиссия Сайта ежемесячно взимается Банком с Оферента согласно тарифам Сайта, указанным на Сайте.<br>
4.4. Комиссия Банка рассчитывается от суммы операции и включается в общую сумму авторизационного запроса, проводимого по Карте, и подлежит списанию без дополнительных распоряжений с Карты отправителя сверх суммы операции в дату списания с Карты отправителя суммы операции.<br>
4.5. Комиссия Сайта рассчитывается ежемесячно и зависит от суммы успешно завершенных Сделок в месяц. Так, если общая сумма завершенных Сделок в месяц не превысила 3 000 (Три тысячи) рублей, комиссия Общества составляет фиксированную сумму 30 (Тридцать) рублей. В случае превышения суммы Сделок 3 000 (Три тысячи) рублей комиссия Общества составляет 1% (Один процент) от суммы Стоимости Работ по каждой Сделке в расчетном месяце.<br>
4.6. В случае совершения возврата денежных средств по Сделке за предшествующий месяц Администрация Сайта производит перерасчет суммы и в случае снижения комиссии направляет распоряжение в Банк о возврате соответствующей части комиссии в случае изменения суммы комиссии Сайта.</p>

<p>5. ОБЯЗАТЕЛЬСТВА ПОЛЬЗОВАТЕЛЕЙ<br>
5.1. При использовании Сервиса Пользователи обязуются:<br>
5.1.1. Надлежащим образом исполнять обязанности, предусмотренные настоящей Офертой, а также обязательства по Сделке, заключенной между Пользователями;<br>
5.1.2. При осуществлении расчетов соблюдать условия его предоставления, предусмотренные настоящей Офертой;<br>
5.1.3. Не использовать Сервис в целях осуществления предпринимательской деятельности;<br>
5.1.4. При заключении Сделки проявлять осмотрительность;<br>
5.1.5. Не осуществлять реализацию в рамках Сделки услуг, не указанных в Заказе.</p>

<p>4. ОБМЕН ИНФОРМАЦИЕЙ ПРИ ИСПОЛЬЗОВАНИИ СЕРВИСА<br>
4.1. В рамках Сервиса Администрация Сайта и Банк могут направлять Пользователям информационные сообщения, связанные с оказанием услуг в рамках Сервиса. Сообщения Администрации Сайта и Банка, предназначенные для Пользователей, направляются им в порядке, предусмотренным настоящим пользовательским Соглашением. Сообщения, опубликованные на Сайте, считаются доставленными Пользователю с момента их публикации. Администрация Сайта может направлять Пользователям транзакционные и сервисные сообщения, связанные с действиями Пользователей в рамках Сервиса.<br>
4.2. Сообщения, обращения Пользователей, предусмотренные настоящей Офертой, а также любые иные обращения Пользователей, предназначенные для Администрации Сайта, пересылаются способами, предложенными на Сайте, включая форму обратной связи для обращений на Сайте.<br>
4.3. Обращения Пользователя к Администрации Сайта по вопросам, связанным с использованием Сервиса, рассматриваются в порядке, предусмотренном настоящей Офертой. Взаимодействие Администрации Сайта с Пользователем в рамках рассмотрения обращения Пользователя осуществляется в порядке, предусмотренным настоящим пользовательским Соглашением.<br>
4.4. Информационно-технологическое взаимодействие между Банком и Оферентом/Акцептантом в части осуществления переводов денежных средств за Работы (Услуги) обеспечивается Администрацией Сайта с использованием программных, аппаратных и технических средств Сайта. Банк при осуществлении переводов денежных средств руководствуется Поручениями Оферента, данными в интерфейсе Сайта.</p>

<p>5. ГАРАНТИИ И ОТВЕТСТВЕННОСТЬ<br>
5.1. Пользователь несет ответственность за действия, совершаемые в рамках Сервиса, в соответствии с действующим законодательством Российской Федерации и условиями настоящей Оферты, включая ответственность за содержание размещаемой им информации и нарушение прав третьих лиц в отношении Работ (Услуг) и/или информации, размещаемой на Сайте и пр.<br>
5.2. Пользователь несет ответственность за предлагаемые в отношении Работ (Услуг) и заключаемые в связи с ними Сделки в рамках Сервиса, за выбор контрагентов для сделки и вытекающие из сделки последствия. Все Сделки в отношении Работ (Услуг) заключаются между Пользователями напрямую. Администрация Сайта/Банк не являются участниками и/или посредниками Сделок в рамках Сервиса, совершаемых Пользователями исходя из информации, полученной на Сайте, не контролируют и не несут ответственности за такие сделки.<br>
5.3. Сервис предназначен для использования в целях, не связанных с осуществлением предпринимательской деятельности. Отношения, являющиеся предметом Сделки, регулируются законодательством Российской Федерации, при этом Пользователи понимают, что отдельные положения законодательства Российской Федерации, в т.ч. в части защиты прав потребителей, могут не применяться к отношениям Пользователей, являющимся предметом Сделки.<br>
5.4. Пользователь согласен, что Администрация Сайта и/или Банк не несет ответственности за возможные убытки, причиненные Пользователю в связи с принятием мер пресечения или предотвращения нарушений на Сайте, связанных с ограничением/блокировкой доступа Пользователей к Сервису.<br>
5.5. Администрация Сайта не несет ответственности за неисполнение или затруднения в исполнении обязательств Пользователей, в т.ч. обязательств по Сделке, вследствие обстоятельств непреодолимой силы, последствия которых невозможно избежать или преодолеть.<br>
5.6. Банк не несет ответственности в случаях, когда зачисление средств на Карту Акцептанта осуществлено с нарушениями сроков и иных требований, установленных правилами ПС и законодательством РФ по вине Банка-эмитента или иных лиц.<br>
5.7. Банк не несет ответственность за несвоевременное сообщение Оферентом и/или Обществом информации о передаче Работ (Услуг) и отсутствии претензий к Акцептанту.<br>
5.8. Банк не несет ответственности за ошибки, допущенные Пользователями при оформлении Поручения, а также за предоставление некорректных реквизитов Карты. В указанных случаях услуги считаются оказанными Банком Оференту надлежащим образом и в полном соответствии с настоящим договором, и Оферент самостоятельно урегулирует дальнейшие взаиморасчеты с Акцептантом или Администрацией Сайта.<br>
5.9. Банк имеет право отказать Пользователю в оказании услуг Банка в одностороннем порядке и без объяснения причин, в том числе, если у Банка отсутствует техническая возможность для их оказания, если параметры операции не соответствуют установленным Банком и/или пользовательским соглашением и/или применимым законодательством ограничениям по суммам и количеству операций по Картам, в случае выявления операций Пользователей, содержащих признаки необычных операций или несущих репутационные и/или финансовые риски для Банка, а также, если у Банка возникли подозрения в том, что операция осуществляется с нарушением требований законодательства РФ и/или правил.</p>

<p>СРОК ДЕЙСТВИЯ ДОГОВОРА<br>
6.1. Заключаемые путем акцепта настоящей Оферты договоры вступают в силу с момента акцепта условий настоящей Оферты Пользователем в порядке, предусмотренном настоящей Офертой, и действует до полного исполнения Сторонами своих обязательство по нему.<br>
6.2. Администрация Сайта оставляет за собой право по собственному усмотрению прекратить или ограничить доступ Пользователя к Сервису.<br>
6.3. Администрация Сайта и/или Банк вправе изменять и/или дополнять условия Оферты в той мере, в какой это допускается действующим законодательством, а также отозвать Оферту в любое время. Датой изменения Оферты является дата опубликования новой редакции Оферты. Пользователь должен ознакомиться с действующей редакцией Оферты, размещенной на Сайте, и с условиями каждой сделки до присоединения к Оферте и совершения сделки.<br>
6.4. Заключение Пользователями Сделки с использованием Сервиса после вступления в силу изменений в Оферте означает, что стороны пришли к согласию о внесении соответствующих изменений и/или дополнений в Оферте.<br>
6.5. Администрация Сайта вправе расторгнуть договоры в порядке, предусмотренном действующим законодательством Российской Федерации, с уведомлением об этом Пользователя через интерфейс Сайта, по адресу электронной почты или иным способом.<br>
6.6. Действие договора об использовании Сервиса может быть прекращено досрочно по инициативе Пользователя путем обращения Пользователя к Администрации Сайта в порядке, предусмотренном настоящей Офертой.</p>

<p>7. СПОРЫ И ДЕЙСТВУЮЩЕЕ ЗАКОНОДАТЕЛЬСТВО<br>
7.1. При разрешении всех споров, вытекающих из настоящей Оферты, применяется действующее законодательство Российской Федерации.<br>
7.2. Споры, возникшие в рамках настоящего договора, должны быть переданы на рассмотрение в соответствии с действующим законодательством Российской Федерации.<br>
7.3. Признание отдельных частей настоящей Оферты недействительными не отменяет действие других ее положений.</p>

<p>8. ПРОЧИЕ УСЛОВИЯ<br>
8.1. Администрация Сайта и/или Банк имеет право, а Пользователь соглашается, на обработку любой информации, относящейся к персональным и/или контактным данным Пользователя, с использованием средств автоматизации или без таковых, включая сбор, систематизацию, накопление, хранение, уточнение, использование, распространение (в том числе передачу партнерам Банка и/или Администрации Сайта, трансграничную передачу), обезличивание, блокирование, уничтожение персональных данных, предоставленных Банку в связи с использованием Сервиса, и иные действия, предусмотренные Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных». Пользователь выражает согласие Банку на предоставление сведений, указанных в настоящем пункте, Банку-эмитенту Карты получателя, для целей исполнения условий настоящей Оферты, а также в любых других целях, прямо или косвенно связанных с исполнением Банком обязательств в рамках настоящей Оферты, и предложение продуктов Банка.<br>
8.2. Пользователь гарантирует, что все условия настоящей Оферты ему понятны, и он принимает условия без оговорок и в полном объеме.<br>
8.3. В случае, если отдельные положения настоящей Оферты прекращают действие по основаниям, предусмотренным законодательством Российской Федерации, это не влечет прекращения или недействительности заключенных между сторонами договоров и его приложений в целом.<br>
8.4. Все действия Пользователей, совершаемые в соответствии с настоящей Офертой, обрабатываются и учитываются Банком и Администрацией Сайта по московскому времени. В качестве языка Договора, заключаемого на условиях настоящей Оферты, а также языка, используемого при любом взаимодействии сторон (включая ведение переписки, предоставление требований/уведомлений/разъяснений, предоставление документов и т.д.), стороны определили русский язык.</p>

<p>9. КОНТАКТНАЯ ИНФОРМАЦИЯ<br>
Пользователи могут связаться с Компанией путем отправления электронного письма на электронный адрес: support@roobo.pro для получения дополнительной информации и отправки запросов в службу поддержки.</p><br>
<br>

        <!-- <h3>Важно!!!</h3>
        <h4>Возврат средств осуществляется в индивидуальном порядке! <br>
Для этого обратитесь на электронную почту: <br> support@roobo.pro</h4>
        <h2>Оферта регулярных платежей</h2><br>
Соглашение<br>
о предоставлении физическим лицам возможности оплаты услуг Индивидуального предпринимателя Яковлева Михаила Геннадьевича посредством регулярных автоматических переводов денежных средств</p>

<p>Индивидуальный предприниматель Яковлев Михаил Геннадьевич, действующий на основании Выписки из Единого государственного реестра индивидуальных предпринимателей, ГРНИП: 321602700021753 от 13.09.2021 именуемый в дальнейшем Поставщик, предлагает Пользователям воспользоваться Сервисом «Рекуррентные списания» и заключить настоящее соглашение (далее – Соглашение), являющееся дополнением к Договору и неотъемлемой частью Договора, об определении порядка расчетов по Договору.<br>
Пользователь безусловно соглашается с настоящим Соглашением пользования сервисом путем совершения конклюдентных действий по подключению Сервиса<br>
«Рекуррентные списания» в момент принятия Пользователем настоящего соглашения пользования сервисом и выполнения действий по активации Сервиса «Рекуррентные списания».</p>

<p>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ<br>
Сервис «Рекуррентные списания» – сервис по осуществлению регулярных автоматических переводов денежных средств с Банковской карты Держателя в пользу Поставщика за предоставляемые Поставщиком услуги по Договору на основании заранее данного акцепта Держателя карты, в соответствии с тарифами и опциями по Договору.<br>
Договор – двустороннее соглашение, заключенное между Пользователем и Поставщиком по условиям которого, Поставщик обязуется предоставить Пользователю Услугу, а последний обязуется оплатить их.<br>
Услуга – предоставление Поставщиком услуги из ассортимента сайта https://roobo.pro/ (сайт магазина)<br>
Пользователь – физическое лицо, заключившее Договор с Поставщиком.<br>
Банк – кредитная организация – АО «Тинькофф Банк», БИК: 044525974, К/с:30101810145250000974.<br>
Запрос – информационное сообщение, направляемое Банком в Банк-эмитент на перевод денежных средств с Банковской карты в пользу Поставщика.<br>
Банк-эмитент – кредитная организация, осуществляющая выпуск Банковских карт, а также расчеты по операциям, совершаемым с использованием Банковских карт.<br>
Процессинговый центр – АО «Тинькофф Банк», БИК: 044525974, К/с:30101810145250000974.<br>
Держатель карты – физическое лицо, дающее распоряжение Банку-эмитенту на перевод денежных средств с Банковской карты от имени Пользователя в пользу Поставщика с помощью Сервиса «Рекуррентные списания».<br>
Банковская карта – расчетная или кредитная карта, эмитентом которой является Банк-эмитент, являющаяся инструментом безналичных расчетов, предназначенная для совершения Держателем карты операций с денежными средствами, находящимися на банковских счетах Держателя карты в Банке-эмитенте, или с денежными средствами, предоставленными Банком-эмитентом в кредит Держателю карты в соответствии с законодательством Российской Федерации, а также договором банковского счета, или в пределах установленного лимита, в соответствии с условиями кредитного договора между Банком-эмитентом и Держателем карты, при условии, что такая расчетная или кредитная карта может быть использована Держателем карты для оплаты Услуг Поставщика. Оплата Услуг Поставщика в соответствии с настоящим Соглашением возможна Банковскими картами платежных систем VISA International, MasterCard International, зарегистрированными в соответствии с действующим законодательством Российской Федерации. При этом возможность использования конкретных видов карт VISA International и MasterCard International устанавливает Банк-эмитент.<br>
Акцепт Пользователя – безусловное соглашение Пользователя с условиями настоящего Соглашения.<br>
Личный кабинет – раздел Сайта Поставщика, защищенный системой контроля доступа, обеспечивающий Пользователю интерфейс для взаимодействия с Поставщиком по Договору.<br>
Сайт Поставщика – сайт, размещенный в сети Интернет по адресу: https://roobo.pro/</p>

<p>Описание Сервиса «Рекуррентные списания» и способ его активации<br>
Сервис «Рекуррентные списания» позволяет Пользователю оплачивать Услуги Поставщика по Договору, путем регулярных автоматических переводов денежных средств в пользу Поставщика с Банковских карт. Активация (подключение) Сервиса «Рекуррентные списания» предполагает выполнение следующих действий:<br>
В Личном кабинете на Сайте Поставщика, Пользователь выбирает раздел «Платежи», подраздел «Сделать платеж»; Пользователь выбирает оплату очередного платежа и ставит флажок напротив пункта «Подключить Автоматический платеж», соглашаясь с настоящим Соглашением пользования Сервисом «Рекуррентные списания» и нажимает кнопку «Оплатить». Сервис «Рекуррентные списания» подключается только для Банковской карты, зарегистрированной Держателем карты.<br>
Регистрация Банковской карты: Держатель карты вводит на WEB-странице ввода платежных данных данные своей Банковской карты.</p>

<p>Действие Сервиса «Рекуррентные списания»<br>
После подключения Сервиса «Рекуррентные списания», с Банковской карты будут осуществляться переводы денежных средств в сроки и размерах, необходимых для надлежащего исполнения обязанности Пользователя по Договору.<br>
 Непосредственное списание денежных средств осуществляет Банк-эмитент на основании заранее данного согласия Пользователя на подобное списание в сумме и в срок, указанные в Договоре. Запрос на перевод денежных средств с указанием суммы перевода в Банк-эмитент передается Банком и Процессинговым центром в рамках заключенных с Поставщиком соглашений.<br>
В один момент времени, у Пользователя может быть активирован (подключен) Сервис «Рекуррентные списания» только по одной Банковской карте платежной системы Visa (Visa Inc.), MasterCard или Maestro (MasterCard International Inc.).<br>
Поставщик не хранит и не обрабатывает данные банковских карт Пользователей, а также иные персональные данные Пользователей, обеспечивая лишь запросы в Процессинговый центр и Банк для повторного проведения операции по банковской карте Пользователя.<br>
Поставщик не гарантирует возможность проведения операций по Банковской карте.<br>
Пользователь гарантирует, что он является Держателем банковской карты, осознанно, корректно и полностью вводит все требуемые реквизиты Банковской карты при активации (подключении) Сервиса «Рекуррентные списания».<br>
Совершение действий, предусмотренных п. 1.1. Соглашения признается аналогом собственноручной подписи Пользователя.<br>
Подключение Сервиса «Рекуррентные списания», в порядке, предусмотренном настоящим Соглашением пользования сервисом, осуществляется только при наличии<br>
технической возможности Поставщика, Процессингового центра, Банка, Банка-эмитента. Поставщик, Процессинговый центр, Банк не несут ответственность за невозможность подключения Сервиса «Рекуррентные списания».<br>
Поставщик имеет право вносить изменения в настоящее Соглашение пользования сервиса, заранее уведомив об этом Пользователя в письменной форме либо иным доступным способом, в том числе путем сообщения на электронную почту, указанную в Договоре Пользователем, либо телефонограммой, либо размещением информации на Веб-Сайте Поставщика.</p>

<p>Отключение Сервиса «Рекуррентные списания»<br>
Отключение (отказ) Пользователя от Сервиса «Рекуррентные списания» происходит:<br>
А) В «Личном кабинете» Пользователь выбирает раздел «Платежи», подраздел «Сделать платеж»;<br>
Б) Нажимает кнопку «Отключить сервис «Автоматический платеж».<br>
При этом денежные средства, переведенные с Банковской карты до отключения (отказа) Пользователя от Сервиса «Рекуррентные списания» за Услуги, Пользователю не возвращаются.<br>
 В случае недостаточности денежных средств на счете Банковской карты для осуществления перевода Поставщик имеет право повторять запрос на списание с Банковской карты денежных средств.</p>

<p>Ограничения при пользовании Сервисом «Рекуррентные списания»<br>
С одной Банковской карты может быть проведено не более 5 (пяти) переводов в сутки.<br>
С одной Банковской карты может быть осуществлен перевод не более, чем на 15000 рублей за одну операцию.<br>
С одной Банковской карты может быть осуществлено переводов не более, чем на 50000 рублей в сутки.<br>
Переход на страницу «Сделать платеж» возможен, лишь в случае если у Пользователя подключено соединение к сети Интернет, независимо от текущего состояния счета.</p>

<p>Ответственность сторон<br>
Поставщик, Процессинговый центр, Банк не несет ответственности за неработоспособность и/или временную неработоспособность Сервиса «Рекуррентные списания». Пользователь самостоятельно контролирует исполнение своей обязанности по оплате по Договору. При неработоспособности и/или временной неработоспособности Сервиса «Рекуррентные списания» Пользователь обязан использовать иные способы исполнения обязанностей по Договору.<br>
Споры сторон, возникшие в связи с выполнением условий настоящего Соглашения, разрешаются в ходе направления претензий.<br>
В случае недостижения Сторонами взаимного согласия, споры между ними разрешаются в соответствии с условиями Договора и Оферты.</p>

<p>Прочие условия<br>
Выполняя действия по подключению Сервиса «Рекуррентные списания»:<br>
А) Пользователь безусловно соглашается со всеми условиями настоящего Соглашения;<br>
Б) Пользователь дает заранее данный акцепт на списание денежных средств<br>
Поставщиком с Банковской карты на условиях настоящего Соглашения;<br>
В) Пользователь предоставляет право Процессинговому Центру, Банку хранить, собирать, обрабатывать свои персональные данные (Фамилию, Имя, Отчество, серию и номер паспорта, место и дату рождения, номер расчетного счета и Банковской карты) с целью реализации настоящего Соглашения;<br>
Г) Пользователь дает согласие на оплату Услуг в соответствии с условиями Соглашения.<br>
Поставщик имеет право отключить и/или ограничить Пользователя от Сервиса «Рекуррентные списания».</p>

<p>ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ И ПОСТАВЩИКА, ВЫТЕКАЮЩИЕ ИЗ НАСТОЯЩЕЙ ОФЕРТЫ, НЕ МОГУТ БЫТЬ ПЕРЕДАНЫ ТРЕТЬИМ ЛИЦАМ БЕЗ ПИСЬМЕННОГО СОГЛАСИЯ ДРУГОЙ СТОРОНЫ.</p>

<p>Я, ПОЛЬЗОВАТЕЛЬ, ОЗНАКОМЛЕН И СОГЛАСЕН С НАСТОЯЩИМ СОГЛАШЕНИЕМ ИСПОЛЬЗОВАНИЯ СЕРВИСА «РЕКУРРЕНТНЫЕ ПЛАТЕЖИ», С АВТОМАТИЧЕСКИМ СПИСАНИЕМ ДЕНЕЖНЫХ СРЕДСТВ С БАНКОВСКОЙ КАРТЫ ЗА УСЛУГИ ПОСТАВЩИКА ПО ДОГОВОРУ.<br>
</p>

<h2>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h2>

<p>Настоящий документ (далее также – Политика) определяет политику в отношении обработки персональных данных пользователей сайта https://roobo.pro/ (далее также – Сайт), принадлежащего ИП Яковлев Михаил Геннадьевич (далее также – Оператор) и определяет порядок обработки персональных данных, условия и принципы обработки персональных данных, права Пользователей и обязанности Администрации Сайта, сведения о реализуемых мерах по защите обрабатываемых персональных данных.<br>
Настоящая Политика обработки персональных данных (далее также – Политика) разработана в соответствии с Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» и иными нормативными правовыми актами Российской Федерации.<br>
Настоящая Политика действует в отношении всех персональных данных, которые администрация Сайта получает от пользователей Сайта.<br>
В случае несогласия с условиями настоящей Политики Пользователь должен немедленно прекратить любое использование Сайта.</p>

<p>1. Термины и определения</p>

<p>1.1. Для целей настоящей Политики используются следующие основные понятия:<br>
1.1.1. Сайт — Web-сайт, расположенный в сети Интернет по адресу https://roobo.pro/ и являющийся совокупностью программно-аппаратных средств и информации, предназначенной для публикации в сети Интернет, отражаемой для Пользователей. Сайт доступен для всех Пользователей сети Интернет.<br>
1.1.2. Оператор – администратор Сайта, осуществляющий обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.<br>
1.1.3. Пользователь – любое дееспособное физическое лицо (либо представитель индивидуального предпринимателя или юридического лица), прошедшее процедуру регистрации на Сайте и принимающее условия настоящего Соглашения; <br>
1.1.4. Контент и материалы — любые размещенные на Сайте или содержащиеся в рассылке объекты интеллектуальной собственности Оператора в форме текстов, изображений, рисунков, фотографий, графиков, видео, программ, звуков, интерфейсов, логотипов, торговых марок, компьютерных кодов, которые являются содержанием Сайта, и/или размещены на Сайте, с согласия Оператора, а также совокупность информационных материалов, рассылаемых Пользователям по электронной почте.<br>
1.1.5. Программное обеспечение (ПО) — браузер (Internet Explorer, FireFox, Google Chrome и аналогичные) для доступа к информационным ресурсам, находящихся в сети Интернет, иные программы для передачи, хранения, обработки предоставляемой информации. Пользователь обязуется самостоятельно обеспечить наличие ПО на своем персональном компьютере.<br>
1.1.6. Персональные данные Пользователя — имя и фамилия, адрес электронной почты, телефон и любая информация, относящаяся к прямо или косвенно определяемому, или определенному лицу, позволяющая определить субъекта персональных данных. Обязательная для предоставления информация помечена специальным образом. Иная информация предоставляется Пользователем на его усмотрение.<br>
1.1.7. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.<br>
1.2. В случае если в настоящей Политике используются термины, не указанные в п. 1.1, то они подлежат использованию и толкованию в соответствии с действующим законодательством, и обычаями делового оборота, сложившимися в сети Интернет.</p>

<p>2. Общие положения</p>

<p>2.1. Настоящая Политика регулирует порядок обработки персональных данных пользователей Сайта, в том числе с целью обеспечения безопасности обработки персональных данных пользователей, обеспечения их прав и интересов при обработке персональных данных в соответствии с законодательством Российской Федерации.<br>
2.2. Использование Пользователем Сайта означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональных данных; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сайта. <br>
2.3. Оператор серьезно относится к конфиденциальности персональных данных пользователей, хранит и обрабатывает их. <br>
2.4. Оператор вправе предоставить доступ к персональным данным пользователей только в исключительных случаях. Такими случаями могут быть: <br>
• требование законодательства или органов власти в соответствии с предусмотренными законами процедурами; <br>
• случаи, когда Пользователь дал согласие на предоставление своих персональных данных, в том числе согласившись с правилами предоставления соответствующей услуги.<br>
2.5. Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (не включено сохранение файлов «cookie» и использование технологии JavaScript).<br>
2.6. В случае несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование Сайта.<br>
2.7. Настоящая Политика конфиденциальности применяется исключительно к Сайту, не контролирует и не несет ответственность за Сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на Сайте.<br>
2.8. Оператор не проверяет достоверность персональных данных, предоставляемых Пользователем.</p>

<p>3. Предмет Политики</p>

<p>3.1. Настоящая Политика устанавливает обязательства Оператора по неразглашению и обеспечению режима защиты конфиденциальности персональных данных, которые Пользователь предоставляет по запросу Оператора для использования Сайта.<br>
3.2. Персональные данные, разрешенные к обработке в рамках настоящей Политики, предоставляются Пользователем путем заполнения форм на Сайте:<br>
- при заполнении Регистрационной формы.<br>
- при оформлении Заказа на Сайте (в том числе путем телефонного подтверждения при помощи Оператора).<br>
- при осуществлении оплаты на Сайте.<br>
3.3. Персональные данные включают в себя следующую информацию:<br>
3.3.1. фамилия, имя, отчество;<br>
3.3.2. дата рождения, <br>
3.3.3. номер телефона, <br>
3.3.4. адрес электронной почты<br>
3.3.5. содержание переписки между Пользователем и Оператором;<br>
3.3.6. информация о посещениях Пользователем Сайта;<br>
3.3.7. ответы Пользователя в каких-либо опросах или анкетах;<br>
3.3.8. информация, которую Оператор может запросить у Пользователя при ответе на его запрос;<br>
3.3.9. любая иная информация, относящаяся прямо или косвенно к определяемому, или определенному лицу, позволяющая определить субъекта персональных данных.<br>
3.4. Также Сайт защищает данные, которые автоматически передаются при посещении страниц:<br>
- IP адрес (данная информация используется с целью предотвращения, выявления и решения технических проблем);<br>
- информация о браузере <br>
- время доступа;<br>
- адрес предыдущей страницы.<br>
3.5. Любая иная персональная информация неоговоренная выше (история посещения, используемые браузеры, операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением случаев, предусмотренных пунктами 5.4.-5.6. настоящей Политики.</p>

<p>4. Цели сбора и обработки персональной информации Пользователя</p>

<p>4.1. Персональные данные Пользователя Оператор может использовать в целях:<br>
4.1.1. Идентификации Пользователя, зарегистрированного на Сайте для его дальнейшей авторизации.<br>
4.1.2. Предоставления Пользователю доступа к Сайту.<br>
4.1.3. Обработки и получения платежей, оспаривания платежа.<br>
4.1.4. Установления с Пользователем обратной связи, включая направление уведомлений, запросов, касающихся использования Сайта, обработки запросов и заявок от Пользователя.<br>
4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.<br>
4.1.6. Предоставления Пользователю эффективной технической поддержки при возникновении проблем, связанных с использованием Сайта.<br>
4.1.7. Предоставления Пользователю с его согласия специальных предложений, новостной рассылки и иных сведений от имени Сайта.<br>
4.1.8. Таргетирование рекламных материалов с целью предоставления наиболее релевантных объявлений о товарах и услугах;<br>
4.2. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты: support@roobo.pro с пометкой «Отказ от уведомлений о новых продуктах и услугах и специальных предложениях».<br>
4.3. Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на Сайте, улучшения качества Сайта и его содержания.</p>

<p>5. Способы и сроки обработки персональной информации</p>

<p>5.1. Обработка персональных данных Пользователя осуществляется без ограничения с использованием автоматизированных сервисов.<br>
5.2. Срок обработки персональных данных является неограниченным. <br>
5.3. Сайт собирает и обрабатывает обезличенные данные о посетителях с помощью сервисов интернет-статистики. <br>
5.4. При входе и просмотре Пользователем страниц Сайта Сайт использует коды ремаркетинга. <br>
5.5. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации. <br>
5.6. Персональные данные Пользователя могут быть переданы строго определенным третьим лицам в той мере, в которой это необходимо для исполнения Договора, а именно в следующих случаях:<br>
5.6.1. если необходимо вовлечь стороннего поставщика услуг для исполнения какого-либо договора, заключенного между Пользователем и Оператором с целью улучшения или расширения сервисов Сайта;<br>
5.6.2. частичного размещения Контента на веб-сайтах деловых партнеров Оператора через API или виджеты;<br>
5.6.3. при продаже или покупке Оператором каких-либо бизнеса или активов, когда Оператор может раскрыть персональные данные будущему Оператору или покупателю указанных бизнеса или активов, согласно применимому законодательству, а также при приобретении Сайта или всех или части его активов третьим лицом, когда имеющиеся у Сайта персональные данные его пользователей будут одним;<br>
5.7. Оператор вправе раскрыть любую собранную о Пользователе информацию, если раскрытие необходимо в связи с расследованием или жалобой в отношении неправомерного использования Сайта либо для установления (идентификации) Пользователя, который может нарушать или вмешиваться в права Оператора или в права других Пользователей.<br>
5.8. Оператор имеет право раскрыть любую информацию о Пользователе, которую посчитает необходимой для выполнения положений действующего законодательства или судебных решений, обеспечения выполнения условий настоящей Политики, защиты прав или безопасности название организации, Пользователей.<br>
5.9. При утрате или разглашении персональных данных Оператор вправе не информировать Пользователя об утрате или разглашении персональных данных.<br>
5.10. Оператор принимает необходимые организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий третьих лиц.<br>
5.11. Оператор совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных последствий, вызванных утратой или разглашением персональных данных Пользователя.</p>

<p>6. Права и обязанности сторон</p>

<p>6.1. Пользователь вправе:<br>
6.1.1. Принимать свободное решение о предоставлении своих персональных данных, необходимых для использования Сайта и давать согласие на их обработку.<br>
6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в случае изменения данной информации.<br>
6.1.3. Получать у Оператора информацию, касающуюся обработки его персональных данных, если такое право не ограничено в соответствии с федеральными законами. Пользователь вправе требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав. Для этого достаточно уведомить Оператора по E-mail адресу: support@roobo.pro <br>
6.2. Оператор обязан:<br>
6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4 настоящей Политики.<br>
6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением пунктов 5.4.-5.6. настоящей Политики.<br>
6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно используемого для защиты такого рода информации в существующем деловом обороте.<br>
6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему Пользователю, с момента обращения или запроса Пользователя, или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период проверки, в случае выявления недостоверных персональных данных или неправомерных действий.</p>

<p>7. Ответственность сторон</p>

<p>7.1. Оператор, не исполнивший свои обязательства, несет ответственность за убытки, понесенные Пользователем в связи с неправомерным использованием персональных данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных пунктом 7.2. настоящей Политики.<br>
7.2. В случае утраты или разглашения Конфиденциальной информации Оператор не несет ответственность, если данная конфиденциальная информация:<br>
7.2.1. Стала публичным достоянием до ее утраты или разглашения.<br>
7.2.2. Была получена от третьей стороны до момента ее получения Оператором.<br>
7.2.3. Была разглашена с согласия Пользователя.<br>
7.3. Пользователь соглашается, что информация, предоставленная ему как часть Сайта, может являться объектом интеллектуальной собственности, права на который защищены и принадлежат Оператору, другим Пользователям, партнерам или рекламодателям, которые размещают такую информацию на Сайте.<br>
7.4. Оператор не несет ответственности перед Пользователем за любой убыток или ущерб, понесенный Пользователем в результате удаления, сбоя или невозможности сохранения какой-либо части сайта или Содержания данных, содержащихся на Сайте или передаваемых через него.<br>
7.5. Оператор не несет ответственности за любые прямые или косвенные убытки, произошедшие из-за: использования либо невозможности использования Сайта, либо отдельных сервисов; несанкционированного доступа к коммуникациям Пользователя; заявления или поведение любого третьего лица на Сайте.</p>

<p>8. Разрешение споров</p>

<p>8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Оператором, обязательным является предъявление претензии (письменного предложения или предложения в электронном виде о добровольном урегулировании спора).<br>
8.2. Получатель претензии в течение 10 календарных дней со дня получения претензии, письменно или в электронном виде уведомляет заявителя претензии о результатах рассмотрения претензии.<br>
8.3. При не достижении соглашения спор будет передан на рассмотрение суда по месту нахождения Оператора.<br>
8.4. К настоящей Политике и отношениям между Пользователем и Оператором применяется действующее законодательство Российской Федерации.</p>

<p>9. Дополнительные условия</p>

<p>9.1. Оператор вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя.<br>
9.2. Новая Политика конфиденциальности вступает в силу с момента ее размещения на Сайте, если иное не предусмотрено новой редакцией Политики конфиденциальности.<br>
9.3. Все предложения или вопросы касательно настоящей Политики конфиденциальности следует сообщать по адресу: support@roobo.pro <br>
9.4. Если Пользователь желает отозвать свое согласие на обработку персональных данных, он должен направить Оператору письменное заявление об отзыве по адресу: support@roobo.pro <br>
В случае отзыва Пользователем согласия на обработку его персональных данных Оператор обязуется прекратить их обработку или обеспечить прекращение такой обработки (если обработка персональных данных осуществляется другим лицом, действующим по поручению Оператора) и, в случае если сохранение персональных данных более не требуется для целей обработки персональных данных, уничтожить персональные данные или обеспечить их уничтожение (если обработка персональных данных осуществляется другим лицом, действующим по поручению Оператора) в срок, не превышающий тридцати дней с даты поступления отзыва.</p>

<h2>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</h2>

<p>Последнее обновление: 5 октября 2021 года</p>

<p>Настоящее Пользовательское соглашение (далее также – «Соглашение») регламентирует правила использования сайта https://roobo.pro/ (далее также – «Сайт») и регулирует отношения между Администрацией Сайта и Пользователями данного сайта.<br>
Сбор и использование персональных данных, осуществляемых Администрацией Сайта описан в Политике Конфиденциальности, расположенной по адресу https://roobo.pro/ХХХХХ/ (необходимо указать адрес страницы, на которой будет расположена политика конфиденциальности).<br>
Администрация Сайта оставляет за собой право в любое время изменять, добавлять или удалять пункты настоящего Соглашения без уведомления Пользователя.<br>
Продолжение использования Сайта Пользователем означает принятие им настоящего Соглашения. <br>
Пожалуйста, внимательно ознакомьтесь с настоящим Соглашением. Регистрируясь, получая доступ, просматривая информацию, загружая (скачивая) информацию или иными способами используя Сайт, Вы признаете, то Вы прочитали, поняли и принимаете условия настоящего Соглашения и настоящим подтверждаете, что действуете добровольно и Ваш возраст достаточен для совершения вышеуказанных действий, в противном случае регистрация, а также иные вышеуказанные действия запрещены и Вы обязуетесь незамедлительно прекратить использование Сайта.<br>
Администрация Сайта оставляет за собой право изменять настоящее Соглашение, с последующим размещением обновленной версии на Сайте. Датой вступления в силу изменений является дата их опубликования на Сайте. </p>

<p>1. Термины и определения</p>

<p>1.1. Для целей настоящего Соглашения используются следующие основные понятия:<br>
1.1.1. Сайт — Web-сайт, расположенный в сети Интернет по адресу https://roobo.pro/ и являющийся совокупностью программно-аппаратных средств и информации, предназначенной для публикации в сети Интернет, отражаемой для Пользователей. Сайт доступен для всех Пользователей сети Интернет.<br>
1.1.2. Администратор Сайта – физическое лицо, представитель Исполнителя, имеющий полномочия принимать решения по жалобам пользователей Сайта. Администратор руководствуется в своей деятельности внутренним убеждением и не несет ответственности за действия пользователей Сайта, а также осуществляет предварительную проверку материалов Авторов, загружаемых на Сайт. Связь с Администратором осуществляется через службу поддержки (https://roobo.pro/ХХХххХХХ ссылка на службу поддержки (чат, обратная связь) и пр.), либо support@roobo.pro .<br>
1.1.3. Оператор – Администратор Сайта, осуществляющий обработку персональных данных, а также определяет цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.<br>
1.1.4. Пользователь – любое дееспособное физическое лицо (либо представитель индивидуального предпринимателя или юридического лица), прошедшее процедуру регистрации на Сайте и принимающее условия настоящего Соглашения; <br>
1.1.5. Посетитель – незарегистрированное или неавторизованное на Сайте лицо, посетившее страницы Сайта. В случае если Посетитель не согласен с условиями Соглашения, он обязуется незамедлительно прекратить просмотр Сайта.<br>
1.1.6. Сервисы Сайта – функциональные возможности, службы, услуги, инструменты, обеспечиваемые программным обеспечением Сайта и доступные для Пользователей. Исполнитель вправе самостоятельно и без уведомления Пользователей изменять Сервисы Сайта с целью улучшения качества оказания услуг по Соглашению. <br>
1.1.7. Автор – Пользователь, разместивший на Сайте предложение о покупке своих товаров и/или услуг.<br>
1.1.8. Партнер – Пользователь, представитель Автора, принявший партнерские условия (Партнерскую систему) продажи Товара Автора.<br>
1.1.9. Покупатель – физическое лицо (либо представитель индивидуального предпринимателя или юридического лица), посетившее Сайт, имеющий намерение приобрести либо приобретший Товар.<br>
1.1.10. Товар – услуги и/или товары, которые реализуются посредством Сайта. Товары разделяются на следующие типы: цифровой товар, подписка (доступ).<br>
1.1.11. Аккаунт – личный кабинет (раздел на Сайте) Пользователя, посредством которого осуществляется управление продажами (покупками) Товаров. Каждый Пользователь получает Аккаунт после прохождения регистрации на Сайте. <br>
1.1.12. Контент и материалы — любые размещенные на Сайте или содержащиеся в рассылке объекты интеллектуальной собственности Оператора в форме текстов, изображений, рисунков, фотографий, графиков, видео, программ, звуков, интерфейсов, логотипов, торговых марок, компьютерных кодов, которые являются содержанием Сайта, и/или размещены на Сайте, с согласия Оператора, а также совокупность информационных материалов, рассылаемых Пользователям по электронной почте.<br>
1.1.13. Программное обеспечение (ПО) — браузер (Internet Explorer, FireFox, Google Chrome и аналогичные) для доступа к информационным ресурсам, находящихся в сети Интернет, иные программы для передачи, хранения, обработки предоставляемой информации. Пользователь обязуется самостоятельно обеспечить наличие ПО на своем персональном компьютере.<br>
1.1.14. Персональные данные Пользователя — имя и фамилия, адрес электронной почты, телефон и любая информация, относящаяся к прямо или косвенно определяемому, или определенному лицу, позволяющая определить субъекта персональных данных. Обязательная для предоставления информация помечена специальным образом. Иная информация предоставляется Пользователем на его усмотрение.<br>
1.1.15. Обработка персональных данных — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.<br>
1.2. В случае если в настоящем Соглашении используются термины, не указанные в пункте 1.1, то они подлежат использованию и толкованию в соответствии с действующим законодательством и обычаями делового оборота, в том числе, сложившимися в сети Интернет.</p>

<p>2. ПРЕДМЕТ СОГЛАШЕНИЯ</p>

<p>2.1. Предметом настоящего Соглашения является предоставление Пользователю (Покупателю) Интернет-ресурса доступа к содержащимся на Сайте Товарам и оказываемым услугам.<br>
2.2. Настоящий Сайт является онлайн платформой, которая предоставляет следующие сервисы:<br>
2.2.1. Использование Авторами функционала Сайта для взаимодействия с Пользователями (включаю отправку текстовых сообщений) в целях предоставления Пользователям доступа (как единовременного, так и подписки) к контенту и/или его части, созданному Авторами и опубликованному в блогах Авторов на Сайте, на безвозмездной основе или за вознаграждение (цену), размер которого определен Автором;<br>
2.2.2. Возможность получения Авторами денежных средств в дар от Пользователей на цели, не противоречащие действующему законодательству Российской Федерации (Донаты);<br>
2.2.3. Предоставление Пользователям доступа к сервисам Сайта, с помощью которых осуществляется продажа (покупка) товаров (услуг), оформляется подписка (доступ), а также предоставление информации о товарах (услугах) и подписках (доступе).<br>
2.2.4. Интеграция с функционалом «VK Donut» в социальной сети vk.com, доступной по адресу https://vk.com и на его поддоменах, управляемой ООО «ВКонтакте», и в мобильных приложениях, подключенных к https://vk.com и управляемых ООО «ВКонтакте»; «ОК Donations» в социальной сети ok.ru, доступной по адресу https://ok.ru/ и на его поддоменах, управляемой ООО «Мэйл.Ру», и в мобильных приложениях, подключенных к https://ok.ru/ и управляемых ООО «Мэйл.Ру»; «MY.GAMES Donations» в Экосистеме MY.GAMES, доступной по адресу https://store.my.games и на его поддоменах, управляемой Май.ком Би.Ви. (My.com B.V.), и в MY.GAMES Игровом центре, управляемом Май.ком Би.Ви. (My.com B.V.). <br>
2.2.5. Иные виды услуг (сервисов), реализуемые на Сайте, включая платные услуги (сервисы).<br>
2.3. Под действие настоящего Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги (сервисы) Сайта, а также любые их последующие модификации и появляющиеся в дальнейшем дополнительные услуги (сервисы) Сайта.<br>
2.4. Настоящее Соглашение является публичной офертой. Получая доступ к Сайту Пользователь считается присоединившимся к настоящему Соглашению. <br>
2.5. Акцепт предполагает, что Покупатель ознакомлен с условиями, порядком оказания и оплаты Товара, а также что продажа Товара осуществляется дистанционно с использованием программного обеспечения Продавцу и полностью соответствует возможности Покупателя приобрести Товар, реализуемый таким способом.<br>
2.6. Администрация Сайта оставляет за собой право изменять условия Оферты в одностороннем порядке без предварительного согласования с Пользователями. Поэтому Администрация Сайта рекомендует Пользователю регулярно проверять условия настоящего Соглашения на предмет его изменения и/или дополнения. Действующая редакция постоянно размещена на Сайте.<br>
2.7. Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации<br>
2.8. За исключением случаев прямого указания в настоящем Соглашении, Администрация Сайта, как лицо, предоставляющее Сайт, не владеет, не создает, не продает, не перепродает, не предоставляет, не контролирует, не управляет, не предлагает, не предоставляет и не поставляет какой-либо Контент Автора и не участвует в каких-либо отношениях между Авторами и Пользователями.<br>
2.9. Авторы и Пользователи самостоятельно несут ответственность за свои действия на Сайте, а также за контент, размещаемый на Сайте.<br>
2.10. Администрация Сайта не является и не становится стороной или другим участником любых договорных отношений между Автором и Пользователем и не является страховщиком, не выступает в качестве агента какого-либо рода для Автора или Пользователя, за исключением случаев, прямо указанных в настоящем Соглашении.<br>
2.11. В силу характера Интернета Администрация Сайта не может гарантировать непрерывное и бесперебойное предоставление и доступность Сайта. Администрация Сайта может ограничить доступность Сайта или определенных областей или функций, если это необходимо с учетом ограничений пропускной способности, безопасности или целостности наших серверов или выполнения мер технического обслуживания, которые обеспечивают надлежащее или улучшенное функционирование Сайта. Администрация Сайта может совершенствовать, улучшать и модифицировать Сайт и время от времени внедрять новые сервисы.</p>

<p>3. АГЕНТСКИЙ ДОГОВОР</p>

<p>3.1. Автор, получающий вознаграждение Автора и/или Донаты от Пользователей посредством сервисов Сайта настоящим назначает Администрацию Сайта в качестве коммерческого агента по сбору платежей с инструкцией и полномочиями действовать в качестве посредника с целью сбора вознаграждения Автора и/или Донатов от Пользователей и их перечисления Автору в соответствии с настоящим Соглашением (Агентский Договор).<br>
3.2. Настоящий Агентский Договор заключается на неопределенный период времени.<br>
3.3. Автор и Администрация Сайта обязуются действовать добросовестно по отношению друг к другу.<br>
3.4. Администрация Сайта взимает агентское вознаграждение с Автора на в соответствии с Условиями Оплаты, которые размещены на странице https://roobo.pro/ХХХххХХХ ссылка на страницу с Условиями Оплаты). Условия Оплаты являются неотъемлемой частью настоящего Агентского Договора.<br>
3.5. Автор несет ответственность за выплату Агентского Вознаграждения, которое он обязан выплатить Администрации Сайта. Если иное не предусмотрено настоящим Соглашением, а также Условиями Оплаты, Агентское Вознаграждение не подлежит возврату.<br>
3.6. По запросу Администрации Сайта, Автор обязуется предоставить Администрации Сайта информацию, необходимую для исполнения Агентского Договора и/или для верификации сумм Агентского Вознаграждения. Политика конфиденциальности Администрации Сайта применяется к любым персональным данным, представленным Администрации Сайта Автором в контексте настоящего Агентского договора.<br>
3.7. Автор понимает, что обязательство Администрации Сайта по перечислению Автору Вознаграждения Автора и/или Донатов возникает и зависит от успешного получения соответствующего Вознаграждения Автора и/или Донатов от Пользователей. Компания гарантирует Авторам перечисление только тех сумм, которые были успешно получены Администрацией Сайта от Пользователей в соответствии с Условиями Оплаты.<br>
3.8. Администрация Сайта сохраняет право изменить Агентское Вознаграждение в любое время.<br>
3.9. Администрация Сайта или Автор могут в любое время расторгнуть Агентский Договор путем письменного уведомления.<br>
3.10. Ни при каких условиях Администрация Сайта не несет ответственность перед Автором за любые прямые, непрямые или случайные убытки, являющиеся причиной или возникающие в связи с или относящиеся к этому Соглашению.<br>
3.11. Ни при каких условиях Администрация Сайта не несет ответственность перед третьими лицами (любые лица, не являющиеся сторонами настоящего Агентского Договора) за любые прямые, непрямые или случайные убытки, являющиеся причиной или возникающие в результате действий или бездействий Автора.<br>
3.12. В случае любого конфликта или несоответствия между условиями настоящего Агентского Договора и другими условиями, содержащимися в Условиях или Условиях Оплаты, условия настоящего Агентского Договора имеют преимущественную силу.</p>

<p>4. УСЛОВИЯ ИСПОЛЬЗОВАНИЯ САЙТА</p>

<p>4.1. Для получения возможности полноценно использовать Сервисы Сайта Посетителю необходимо пройти процедуру регистрации, и получить статус Пользователя.<br>
4.2. Регистрация осуществляется путем заполнения специальной формы на Сайте и подтверждения адреса электронной почты.<br>
4.3. Каждый Пользователь может стать Автором, зарегистрировав Блог, где такой пользователь может предоставлять другим пользователям товары. <br>
4.4. Для осуществления платежных операций на Сайте Пользователю необходимо иметь «электронный кошелек» (Яндекс.Деньги или Qiwi или др.).<br>
4.5. Администрация Сайта обеспечивает конфиденциальность полученных от Посетителей и Пользователей персональных и иных данных, в соответствии с «Политикой конфиденциальности», являющейся неотъемлемой частью Соглашения.<br>
4.6. Администрация Сайта вправе запросить у Пользователя любые документы, подтверждающие сведения, указанные им при регистрации, а также подтверждающие наличие у Автора прав (в том числе интеллектуальных) на Товары.<br>
4.7. Пользователь вправе действовать на Сайте анонимно, однако Администрация Сайта оставляет за собой право в любой момент приостановить активность анонимного Пользователя и потребовать его идентификации, в том числе запросить у Пользователя направить с этой целью скан своего паспорта. Невыполнение требований Администрации Сайта по идентификации Пользователя, а также предоставление Пользователем при регистрации недостоверной информации влечет за собой блокировку Аккаунта и расторжение Соглашения в одностороннем внесудебном порядке Администрацией Сайта.<br>
4.8. Все действия, совершенные с использованием Аккаунта Пользователя, считаются совершенными самим Пользователем и в полной мере возлагают на него ответственность за такие действия.<br>
4.9. Пользователь лично принимает необходимые и возможные меры для предотвращения несанкционированного доступа третьих лиц к своему Аккаунту. Пользователь несет полную ответственность за безопасность своего пароля, сохранность своих данных и передачу логина и пароля третьим лицам.<br>
4.10. О несанкционированном доступе к своему логину и паролю, Пользователю надлежит незамедлительно сообщить об этом Администратору Сайта.<br>
4.11. Пользователь обязуется не предпринимать никаких действий, которые могут привести к непропорционально большой нагрузке на Сайт.<br>
4.12. Сайт может содержать ссылки на другие ресурсы сети Интернет. Настоящим Пользователь соглашается с тем, что Администрация Сайта не несет никакой ответственности за доступность этих ресурсов и за информацию, размещенную на них, а также за любые последствия, связанные с использованием этих ресурсов.<br>
4.13. Если Пользователь намеревается получить доступ к контенту Автора c ограниченным доступом, такой Пользователь должен подписаться на Блог и уплатить вознаграждение Автора в размере, определенном Автором. Каждый Автор может установить порядок оплаты вознаграждения Автора как однократный платеж за определенную часть контента Автора и/или повторяющиеся платежи за получение и продление доступа к контенту Автора, опубликованному или подлежащему опубликованию в будущем до тех пор, пока такие платежи осуществляются.<br>
4.14. Для расчета и оплаты платежей за подписку берется расчетный период «месяц», равный тридцати (30) календарным дням. Активируя подписку Пользователь соглашается с тем, что последующие платежи за подписку будут автоматически списываться со счета Пользователя в конце каждого периода, кратного 30 дням, если только Пользователь не отменит подписку до даты очередного платежа.<br>
4.15. Размер Донатов при использовании системы VK Donut не должен быть менее 50 (пятидесяти) российских рублей и не может превышать 2 000 (две тысячи) российских рублей в месяц, при использовании систем OK Donations и MY.GAMES Donations не может быть менее 1 (одного) российского рубля и не может превышать 5 000 (пять тысяч) российских рублей в месяц. Общий размер Донатов не может превышать более 100 000 (сто тысяч) российских рублей в месяц на одного Пользователя (Автора). Возврат Донатов не производится.</p>

<p>5. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТВЕННОСТЬ</p>

<p>5.1. Администрации Сайта принадлежит исключительное право на все результаты интеллектуальной деятельности, размещенные им на Сайте (изображения, аудиовизуальные произведения, тексты, программы для ЭВМ и пр.).<br>
5.2. Пользователь и Посетитель не вправе использовать, в целях не обусловленных Соглашением, объекты исключительных прав Администрации Сайта без его специального разрешения, составленного в письменной форме. Под использованием понимаются действия, перечисленные в п. 2 ст. 1270 ГК РФ, а также иные действия, нарушающие права Администрации Сайта, независимо от того, совершаются ли такие действия с целью извлечения выгоды или без таковой.<br>
5.3. Автор или Пользователь, разместивший на Сайте контент несет единоличную ответственность за него и последствия его размещения. <br>
5.4. Загружая (публикуя, передавая, предоставляя доступ) Автор (Пользователь) заверяет и гарантирует, что:<br>
5.4.1. Является создателем и обладателем контента или иным образом обладает достаточными правами и полномочиями на предоставление прав в соответствии с настоящим Соглашением;<br>
5.4.2. Данный контент всегда соответствует требованиям настоящего Соглашения и действующего законодательства Российской Федерации, не нарушает или незаконно присваивает какое-либо право третьего лица, включая любое авторское право, товарный знак, патент, коммерческую тайну, моральное право, право на конфиденциальность, право на гласность или иное право интеллектуальной собственности или право собственности; не оскорбляет, оговаривает или дискредитирует любое другое лицо;<br>
5.4.3. Данный контент не содержит какие-либо вирусы, рекламное программное обеспечение, шпионское программное обеспечение, самораспространяющиеся программы или иной вредоносный код.<br>
5.5. Пользователю и Посетителю предоставляется личное неисключительное и непередаваемое право использовать Сайт при условии, что ни сам Пользователь или Посетитель, ни любые иные лица при содействии со стороны Пользователя или Посетителя не будут:<br>
-копировать или изменять программное обеспечение или объекты интеллектуальной собственности на Сайте;<br>
-создавать программы, производные от программного обеспечения; проникать в программное обеспечение с целью получения кодов программ;<br>
-осуществлять продажу, уступку, сдачу в аренду, передачу третьим лицам в любой иной форме прав в отношении программного обеспечения, предоставленных Пользователю по Соглашению, а также модифицировать Сервисы Сайта, в том числе с целью получения несанкционированного доступа к ним.<br>
5.6. При размещении на Сайте любых объектов интеллектуальной собственности Пользователь гарантирует соблюдение положений законодательства и прав третьих лиц и принимает на себя ответственность за нарушения.<br>
5.7. Все спорные ситуации, связанные с нарушением прав третьих лиц на объекты интеллектуальной собственности, Пользователь и Посетитель обязуются урегулировать самостоятельно и за свой счет. Пользователь также компенсирует Администрации Сайта все понесенные в связи с подобными спорами убытки и издержки в полном объеме.<br>
5.7. В случае поступления в адрес Администрации Сайта обоснованного заявления (как частных лиц, так и органов власти) о нарушении Пользователем законодательства или прав третьих лиц на размещенные им объекты интеллектуальной собственности Администратор удаляет такие объекты в одностороннем порядке. Также Администратор вправе заблокировать Аккаунт Пользователя. Исключение составляют случаи, когда у Администратора имеются доказательства правомерности размещения Пользователем спорных объектов.<br>
5.8. Администрация Сайта не принимает на себя какой-либо ответственности за любой контент, размещаемый, хранящийся или загружаемый Пользователями, либо за любые убытки или ущерб в связи с ним, а также Администрация Сайта не несет ответственности за какие-либо ошибки, клеветнические заявления, оскорбления, диффамацию, упущения, ложные заключения, ругательства, порнографию или профанацию, которые могут быть обнаружены. <br>
5.9. В любое время Администрация Сайта оставляет за собой право на удаление или блокирование любого Пользовательского контента в случае нарушения или предполагаемого нарушения настоящего Соглашения.</p>

<p>6. ОГРАНИЧЕНИЕ ОТВЕТСТВЕННОСТИ</p>

<p>6.1. Пользователь и Посетитель соглашаются с тем, что Сайт соответствует их требованиям на момент принятия Соглашения и будет соответствовать им впоследствии.<br>
6.2. Ни при каких обстоятельствах Администрация Сайта и ее представители не несут ответственность перед Пользователем, Посетителем или перед любыми третьими лицами за любой косвенный, случайный, неумышленный ущерб, включая упущенную выгоду или потерянные данные, вред чести, достоинству или деловой репутации, причиненный в связи с использованием Сайта, содержимого Сайта или иных материалов, к которым Пользователь, Посетитель или иные лица получили доступ с помощью Сайта, даже если Администрация Сайта предупреждала или указывала на возможность такого вреда.<br>
6.4. Пользователь и Посетитель несут полную ответственность за все свои действия на Сайте и за последствия этих действий.<br>
6.5. Если в результате нарушения Пользователем действующего законодательства, регулирующего размещение информации и объектов интеллектуальной собственности в сети Интернет, с Администрации Сайта в судебном порядке будут взысканы штраф и/или иные выплаты, Пользователь обязуется компенсировать Исполнителю данные денежные суммы в полном объеме на основании претензии последнего.<br>
 <br>
7. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</p>

<p>7.1. Администрация Сайта вправе в одностороннем порядке расторгнуть Соглашение и заблокировать Аккаунт Пользователя без возможности восстановления по основаниям и в порядке, предусмотренным Соглашением, а также действующим законодательством, в том числе международными соглашениями.<br>
7.2. Все разногласия или споры, которые могут возникнуть между сторонами Соглашения в связи с его исполнением должны разрешаться в досудебном порядке путем переговоров, направления претензионных писем. Срок ответа на претензию – 10 (Десять) рабочих дней.<br>
7.3. К отношениям сторон по Соглашению применяется законодательство Российской Федерации.<br>
7.4. Соглашение составлено на русском языке. Принимая Соглашение, Пользователь декларирует, что текст Соглашения ему понятен и в переводе не нуждается. В случае необходимости Пользователь обязуется самостоятельно и за свой счет осуществить перевод Соглашения на другой, понятный ему язык, либо прекратить использование Сайта.<br>
7.5. Если согласие по каким-либо причинам не будет достигнуто в ходе досудебного урегулирования, спор, вытекающий из Соглашения, подлежит рассмотрению в соответствии с действующим законодательством Российской Федерации.</p>

<br>
<p>Неотъемлемыми частями Соглашения является:<br>
1) Политика конфиденциальности, действующая редакция которого расположена по адресу: <a href="https://roobo.pro/info">https://roobo.pro/info</a> <br>
2) Политика использования файлов cookies, действующая редакция которого расположена по адресу: <a href="https://roobo.pro/info">https://roobo.pro/info</a><br>
3) Условия оплаты, действующая редакция которых расположена по адресу: <a href="https://roobo.pro/info">https://roobo.pro/info</a><br>
 </p>

<br>
<p>По всем вопросам, связанным с использованием Сайта, Вы можете обратиться по следующему адресу: support@roobo.pro </p><br>

<h2>УСЛОВИЯ ОПЛАТЫ</h2>

<p>Последнее обновление: 01 октября 2021 года</p>

<br>
<p>Настоящие условия оплаты (далее – Условия) определяют порядок зачисления (списания, перевода) вознаграждения автора и (или) доната, а также определяет размер агентского вознаграждения и порядок его списания (удержания).</p>

<br>
<p>ОБЩИЕ УСЛОВИЯ</p>

<p>Администрация Сайта будет собирать Вознаграждение Автора и/или Донаты от Пользователей и перечислять их Авторам после вычета суммы Агентского Вознаграждения (как указано в Таблице 1). Администрация Сайта будет предпринимать действия для осуществления таких переводов на ежедневной основе, но не может гарантировать это. Автор обязан выбрать валюту и платежный способ из доступных на Сайте вариантов, который будет впоследствии использоваться в качестве валюты по умолчанию («Основная валюта») и платежного метода по умолчанию («Основной платежный метод») для получения Вознаграждения Автора и/или Донатов. Когда Автор выбирает платежный способ, он/она должен предоставить Администрации Сайта действительную информацию, запрашиваемую на Сайте или запрашиваемую Администрацией Сайта. В случае если Вознаграждение Автора и/или Донаты получены в валюте, отличной от Основной валюты, они будут сконвертированы, отражены и выплачены Автору в Основной валюте по курсу Центрального Банка Российской Федерации (http://www.cbr.ru/) на день его получения Администрацией Сайта.<br>
Если Автор выбирает платежный метод, который не позволяет получить полную сумму Вознаграждения Автора и/или Донатов из-за ограничений, установленных применимым поставщиком платежных услуг, тогда Автор должен выбрать дополнительный или альтернативный способ оплаты, если такая возможность предоставлена Сайтом, или Администрация Сайта имеет право перечислять Вознаграждение Автора и/или Донаты, причитающиеся к выплате Автору, на ежедневной основе до тех пор, пока не будет выплачена полная сумма Вознаграждения Автора и/или Донатов. Если Автор выбирает платежный метод, который не позволяет получать Вознаграждение Автора и/или Донаты в Основной валюте из-за ограничений применимого платежного провайдера, тогда Вознаграждение Автора и/или Донаты будут перечислены в разрешенной провайдером валюте по курсу Центрального Банка Российской Федерации (http://www.cbr.ru/) на день перечисления Вознаграждения Автора и/или Донатов Компанией.<br>
Администрация Сайта настоящим информирует Пользователей и Авторов, что перечисление Донатов не является фактом оплаты каких-либо услуг Авторов. Донаты и Вознаграждение Автора являются добровольными платежами, Пользователи самостоятельно определяют их размер в пределах, доступных в интерфейсах Сайта. Вознаграждение Автора или Донаты не могут быть возвращены Пользователю после перечисления Автору. Пользователь вправе обратиться с жалобой на факт перечисления Донатов или Вознаграждения Автора, при этом Администрация Сайта оставляет за собой право автоматически отказать Пользователю в жалобе, если такая жалоба была подана по истечении календарных суток, в течение которых был совершен платеж. В ином случае Администрация Сайта оставляет за собой право руководствоваться п.1.4 настоящих Условий.<br>
Администрация Сайта вправе отложить на необходимый срок (но в любом случае не более чем на 90 дней) и/или отменить любой сбор Вознаграждения Автора и/или Донатов от Пользователя и/или последующее их перечисление Автору с целью предотвращения незаконной деятельности или мошеннических действий, оценки рисков, безопасности или расследования. В том случае, если Вознаграждение Автора и/или Донаты превышают максимальный размер, установленный Условиями использования, Администрация Сайта отложит выплату такого Вознаграждения Автора и/или Донатов на срок не менее 5 (пяти) рабочих дней с целью, указанной выше.<br>
Администрация Сайта вправе вычитать или удерживать любые налоги, в отношении которых у Компании существует обусловленная законом обязанность по вычету или удержанию, из любых сумм, подлежащих оплате Автору по Агентскому Договору. Время от времени Администрация Сайта может запрашивать у вас налоговую информацию, и вы должны предоставить ее Администрации Сайта.<br>
Начиная с первого календарного дня, следующего за днем присвоения Автору Статуса Неактивного Автора, стоимость услуг по поддержанию Учетной записи на Сайте составляет 10 (десять) рублей 00 копеек за 1 (один) календарный день. В случае если Основной валютой являются не российские рубли, то указанная выше сумма будет составлять 0 Евро 13 центов, если Основная валюта – Евро, и 0 долларов США 15 центов, если Основная валюта – доллары США.<br>
«Статус Неактивного Автора» - статус Автора, устанавливаемый Администрацией Сайта, в случае если Автор в течение более чем трех календарных месяцев не использовал Учетную Запись, либо не выбрал платежный способ, который используется по умолчанию Администрацией Сайта для перечисления денежных средств Автору.<br>
Соглашение автоматически расторгается в случае если Автору присвоен Статус Неактивного Автора, и сумма Вознаграждения Автора и/или Донатов, подлежащая уплате Автору, равна нулю.</p>

<p>ОБЩЕЕ АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ</p>

<p>Раздел 2 применяется только к Вознаграждению Автора и Донатам, перечисляемым вне VK Donut, OK Donations и MY.GAMES Donations, как описано в Условиях Предоставления Услуг.<br>
Сумма Агентского Вознаграждения за услуги должна рассчитывается в зависимости от платежного способа, который был использован Пользователем для перечисления Вознаграждения Автора и/или Донатов, и суммы Вознаграждения Автора и/или Донатов в соответствии с Таблицей 1:</p>

<p>Таблица 1</p>

<p>Платежный способ Агентское Вознаграждение<br>
Банковские карты 7%<br>
QIWI Wallet 7%<br>
PayPal 7%<br>
ЮMoney 7%<br>
Apple Pay 7%</p>

<p>Автор вправе выбрать следующие доступные платежные способы для получения Вознаграждения Автора и/или Донатов в соответствии с настоящими Условиями при условии, что обязательство Администрации Сайта по перечислению Вознаграждения Автора и/или Донатов считается исполненным с момента зачисления средств на соответствующий счет платежной системы Автора. С Автора взимается комиссия за перевод Вознаграждения Автора и/или Донатов, как указано в Таблице 2 ниже.</p>

<p>Таблица 2</p>

<p>Платежный метод % Вознаграждения Автора и/или Донатов<br>
QIWI Wallet 2%<br>
WebMoney (WMR, WMZ, WME) 1,5%<br>
Банковские карты* 2,3% + 25 рублей<br>
Банковские карты (бета)** 1,7%<br>
PayPal 2,5%<br>
ЮMoney 2,7%<br>
VK Pay 0%</p>

<p>*Ограничения для банковских карт:<br>
Переводы на банковские карты. Сумма единоразового перевода на банковские карты не может быть меньше 150 рублей и не может превышать 60 000 рублей. Совокупная сумма Вознаграждения Автора и/или Донатов за 1 месяц не может превышать 600 000 рублей.</p>

<p>**Ограничения для банковских карт (бета):<br>
Переводы на банковские карты. Сумма единоразового перевода на банковские карты не может быть меньше 150 рублей и не может превышать 100 000 рублей. Комиссия за единоразовый перевод на банковскую карту Вознаграждения Автора и/или Донатов не может быть меньше 30 рублей. Совокупное количество переводов на банковские карты не может превышать 50 штук. Совокупная сумма Вознаграждения Автора и/или Донатов за 1 месяц не может превышать 1 500 000 рублей.</p>

<br>
<p>АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ ДЛЯ VK DONUT</p>

<p>Раздел 3 применяется только к Вознаграждению Автора и Донатам, перечисляемым с использованием VK Donut, как описано в Условиях Предоставления Услуг, и процент Агентского вознаграждения включает в себя расходы Администрации Сайта, понесенные при выполнении и поддержке интеграции определенных Услуг Сайта для каждого из Пользователей и Авторов в VK Donut.<br>
Cумма Агентского вознаграждения рассчитывается в зависимости от способа оплаты, который был использован Пользователем для перечисления Вознаграждения Автора и/или Донатов, и суммы Вознаграждения Автора и/или Донатов в соответствии с Таблицей 3:</p>

<p>Таблица 3</p>

<p>Платежный способ Агентское Вознаграждение<br>
Банковские карты 10%<br>
VK Pay 10%</p>

<p>Автор, использующий VK Donut, вправе выбрать следующие доступные платежные способы для получения Вознаграждения Автора и/или Донатов в соответствии с настоящими Условиями при условии, что обязательство Администрации Сайта по перечислению Вознаграждения Автора и/или Донатов считается исполненным с момента зачисления средств на соответствующий счет платежной системы Автора. С Автора взимается комиссия за перевод Вознаграждения Автора и/или Донатов, как указано в Таблице 4:</p>

<p>Таблица 4</p>

<p>Платежный метод % Вознаграждения Автора и/или Донатов<br>
Банковские карты* 2,3% + 25 рублей<br>
Банковские карты (бета)** 1.7%<br>
VK Pay 0%</p>

<p>*Ограничения для банковских карт:<br>
Переводы на банковские карты. Сумма единоразового перевода на банковские карты не может быть меньше 150 рублей и не может превышать 60 000 рублей. Совокупная сумма Вознаграждения Автора и/или Донатов за 1 месяц не может превышать 600 000 рублей.</p>

<p>**Ограничения для банковских карт (бета):<br>
Переводы на банковские карты. Сумма единоразового перевода на банковские карты не может быть меньше 150 рублей и не может превышать 100 000 рублей. Комиссия за единоразовый перевод на банковскую карту Вознаграждения Автора и/или Донатов не может быть меньше 30 рублей. Совокупное количество переводов на банковские карты не может превышать 50 штук. Совокупная сумма Вознаграждения Автора и/или Донатов за 1 месяц не может превышать 1 500 000 рублей.</p>

<br>
<p>АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ ДЛЯ OK DONATIONS</p>

<p>Раздел 4 применяется только к Вознаграждению Автора и Донатам, перечисляемым с использованием OK Donations, как описано в Условиях Предоставления Услуг, и процент Агентского вознаграждения включает в себя расходы Администрации Сайта, понесенные при выполнении и поддержке интеграции определенных Услуг Сайта для каждого из Пользователей и Авторов в OK Donations.<br>
Сумма Агентского вознаграждения рассчитывается в зависимости от способа оплаты, который был использован Пользователем для перечисления Вознаграждения Автора и/или Донатов, и суммы Вознаграждения Автора и/или Донатов в соответствии с Таблицей 5:</p>

<p>Таблица 5</p>

<p>Платежный способ Агентское Вознаграждение<br>
Банковские карты 5%</p>

<p>Автор, использующий OK Donations, вправе выбрать следующие доступные платежные способы для получения Вознаграждения Автора и/или Донатов в соответствии с настоящими Условиями Оплаты при условии, что обязательство Администрации Сайта по перечислению Вознаграждения Автора и/или Донатов считается исполненным с момента зачисления средств на соответствующий счет платежной системы Автора. С Автора взимается комиссия за перевод Вознаграждения Автора и/или Донатов, как указано в Таблице 6:</p>

<p>Таблица 6</p>

<p>Платежный метод % Вознаграждения Автора и/или Донатов<br>
Банковские карты* 2,3% + 25 рублей<br>
Банковские карты (бета)** 1.7%<br>
QIWI 2%</p>

<p>*Ограничения для банковских карт:<br>
Переводы на банковские карты. Сумма единоразового перевода на банковские карты не может быть меньше 150 рублей и не может превышать 60 000 рублей. Совокупная сумма Вознаграждения Автора и/или Донатов за 1 месяц не может превышать 600 000 рублей.</p>

<p>**Ограничения для банковских карт (бета):<br>
Переводы на банковские карты. Сумма единоразового перевода на банковские карты не может быть меньше 150 рублей и не может превышать 100 000 рублей. Комиссия за единоразовый перевод на банковскую карту Вознаграждения Автора и/или Донатов не может быть меньше 30 рублей. Совокупное количество переводов на банковские карты не может превышать 50 штук. Совокупная сумма Вознаграждения Автора и/или Донатов за 1 месяц не может превышать 1 500 000 рублей.</p>

<br>
<p>АГЕНТСКОЕ ВОЗНАГРАЖДЕНИЕ ДЛЯ MY.GAMES DONATIONS</p>

<p>Раздел 5 применяется только к Вознаграждению Автора и Донатам, перечисляемым с использованием MY.GAMES Donations, как описано в Условиях Предоставления Услуг, и процент Агентского вознаграждения включает в себя расходы Администрации Сайта, понесенные при выполнении и поддержке интеграции определенных Услуг Сайта для каждого из Пользователей и Авторов в MY.GAMES Donations.<br>
Сумма Агентского вознаграждения рассчитывается в зависимости от способа оплаты, который был использован Пользователем для перечисления Вознаграждения Автора и/или Донатов, и суммы Вознаграждения Автора и/или Донатов в соответствии с Таблицей 7:</p>

<p>Таблица 7</p>

<p>Платежный способ Агентское Вознаграждение<br>
Банковские карты 7%<br>
VK Pay 7%</p>

<p>Автор, использующий MY.GAMES Donations, вправе выбрать следующие доступные платежные способы для получения Вознаграждения Автора и/или Донатов в соответствии с настоящими Условиями Оплаты при условии, что обязательство Администрации Сайта по перечислению Вознаграждения Автора и/или Донатов считается исполненным с момента зачисления средств на соответствующий счет платежной системы Автора. С Автора взимается комиссия за перевод Вознаграждения Автора и/или Донатов, как указано в Таблице 8:</p>

<p>Таблица 8</p>

<p>Платежный метод % Вознаграждения Автора и/или Донатов<br>
Банковские карты* 2,3% + 25 рублей<br>
Банковские карты (бета)** 1.7%<br>
VK Pay 0%<br>
QIWI Wallet 2%<br>
WebMoney (WMR, WMZ, WME) 1,5%<br>
PayPal 2,5%</p>

<p>*Ограничения для банковских карт:<br>
Переводы на банковские карты. Сумма единоразового перевода на банковские карты не может быть меньше 150 рублей и не может превышать 60 000 рублей. Совокупная сумма Вознаграждения Автора и/или Донатов за 1 месяц не может превышать 600 000 рублей.</p>

<p>**Ограничения для банковских карт (бета):<br>
Переводы на банковские карты. Сумма единоразового перевода на банковские карты не может быть меньше 150 рублей и не может превышать 100 000 рублей. Комиссия за единоразовый перевод на банковскую карту Вознаграждения Автора и/или Донатов не может быть меньше 30 рублей. Совокупное количество переводов на банковские карты не может превышать 50 штук. Совокупная сумма Вознаграждения Автора и/или Донатов за 1 месяц не может превышать 1 500 000 рублей.</p><br>
<br>
<h2>Политика использования файлов cookie</h2>

<p>Настоящая политика вступает в действие с 01.10.2021 г.</p>

<p>Настоящая Политика использования файлов cookie определяет основы использования нами файлов cookie на веб-сайте https://roobo.pro/ (далее — «Веб-сайт»).<br>
При первом посещении нашего Веб-сайта вы будете проинформированы об использовании файлов cookie и соответствующих возможностях их использования.<br>
Если вы продолжите пользоваться Веб-сайтом, неизменив настройки cookie, то вы тем самым выражаете согласие на использование файлов cookie на Веб-сайте в соответствии с настоящей политикой в отношении данного типа файлов. Текст согласия на использование файлов cookie находится в конце Политики конфиденциальности.<br>
Вы можете изменить свои настройки в любое время.<br>
Если вы не согласны с тем, чтобы мы использовали данный тип файлов, то вы должны соответствующим образом установить настройки вашего браузера или не использовать настоящий Веб-сайт.<br>
Мы имеем право в одностороннем порядке вносить изменения в настоящую Политику путем обновления данной страницы.<br>
Вам следует периодически просматривать эту страницу, чтобы убедиться, что вас устраивают возможные изменения.</p>

<p>Определение и виды cookie-файлов<br>
Cookie — файлы или фрагменты информации, которые могут быть сохранены на вашем компьютере (или других интернет-совместимых устройствах, таких как смартфон или планшет), когда вы посещаете Веб-сайт. В данном типе файла может храниться различная информация, такая как тип браузера, используемая операционная система, языковые настройки или другие персональные настройки страницы, данные об использовании вами сайта, информация о заказе или данные входа в систему. Как правило, файлы cookie не используются для сбора данных, которые позволяют непосредственно идентифицировать физическое лицо. Однако информация, получаемая посредством файлов cookie, может быть соотнесена с физическим лицом в момент предоставления персональной информации такого физического лица в сочетании с такой информацией, как адрес электронной почты.<br>
Также могут существовать другие технологии, принцип работы которых схож с файлами cookie и которые сохраняют данные в вашем браузере или на вашем устройстве. В то время как вы, возможно, не сможете с помощью настроек вашего браузера отказаться от использования этих технологий отслеживания, такие технологии обычно работают только совместно с файлами cookie. Соответственно, отказ от использования файлов cookie в вашем браузере в большинстве случаев также отключит эти технологии. Все эти технологии далее совместно именуются "файлы cookie".<br>
Файлы cookie используются для:<br>
настройки содержимого страниц Веб-сайта в соответствии с предпочтениями Пользователя, а также оптимизации Веб-сайта; в частности, эти файлы позволяют распознать ваше устройство и, соответственно, настроить просмотр страницы Веб-сайта под ваши индивидуальные потребности;<br>
создания статистики, которая помогает понять, как Пользователи используют Веб-сайт, это позволяет улучшить структуру и содержание Веб-сайта;<br>
поддержания сеанса Пользователя Веб-сайта (после входа в систему), благодаря чему Пользователи не должны повторно вводить логин и пароль на каждой странице.<br>
Таким образом, файлы cookie не наносят вреда вашему устройству и позволяют нам предоставлять вам более быструю и лучшую навигацию по Веб-сайту. Cookie присутствуют, как правило, в значительном количестве в каждом пользовательском браузере и порой сохраняются надолго.<br>
На нашем Веб-сайте используются два разных вида файлов cookie: "временные" (session cookies) и "постоянные" (persistent cookies).<br>
Временные cookie являются файлами, которые хранятся в вашем устройстве до выхода с Веб-сайта или отключения программного обеспечения (веб-браузера).<br>
Постоянные cookie хранятся в вашем устройстве в течение времени, указанного в параметрах файлов cookie или до момента их удаления вами.</p>

<p>Список файлов cookie, которые использует Веб-сайт при своем функционировании</p>

<p>Обязательные файлы cookie<br>
Данные файлы cookie необходимы, чтобы Веб-сайт работал корректно. Они позволяют Вам передвигаться по нашему Веб-сайту, использовать его возможности. Эти файлы не идентифицируют вас как личность. Если вы не согласны использовать данный тип файлов, это может оказать влияние на производительность Веб-сайта или его компонентов.</p>

<p>Файлы cookie для отслеживания<br>
Мы используем файлы cookie для отслеживания, чтобы изменять комиссии, выплачиваемые деловым партнерам, с которыми мы сотрудничаем. Для этих целей с помощью файлов cookie для отслеживания документируются случаи и время перехода по ссылкам на те или иные предложения. Если впоследствии вы приобретете что-либо у определенного делового партнера, то файл cookie идентифицирует вас как покупателя, перенаправленного с нашего Веб-сайта и мы заработаем комиссию. Эти файлы cookie служат исключительно для корректного соотнесения продажи с соответствующим предложением на нашем Веб-сайте.</p>

<p>Функциональные файлы cookie<br>
Данные файлы cookie позволяют «запомнить» какой выбор вы сделали (например, ввели имя пользователя, язык и регион, где вы находитесь), чтобы сделать работу с Веб-сайтом эффективной и индивидуальной. Информация, собираемая с данного типа файлов, может включать личную информацию, которую вы предоставили, например, ваше имя пользователя или информацию о заказах. Мы всегда будем информировать вас о том, что за информацию мы собираем, что мы с ней делаем и как мы ее обрабатываем. Если вы блокируете этот тип файлов, то это может повлиять на производительность и функциональность Веб-сайта и может ограничить доступ к контенту на Веб-сайте.</p>

<p>Рекламные и целевые файлы cookie<br>
Данные файлы cookie позволяют предоставлять рекламный контент, который может заинтересовать вас и быть интересным. Они могут быть использованы для доставки целевой рекламы или с целью ограничения количества просмотров рекламы. Они также помогают нам оценивать эффективность рекламных кампаний, проводимых на Веб-сайте и веб-ресурсах других компаний. Мы можем использовать эти cookie для фиксации информации о тех сайтах, которые вы посещали, и мы можем делиться этой информацией с другими сторонами, включая рекламодателей и агентства. Информация, собираемая с данного типа файлов, может включать личную информацию, которую вы предоставили. Мы всегда будем информировать вас о том, что за информацию мы собираем, что мы с ней делаем и как мы ее обрабатываем. Если вы блокируете этот тип файлов, то это может повлиять на производительность и функциональность Веб-сайта и может ограничить доступ к контенту на Веб-сайте.</p>

<p>Файлы cookie социальных сетей<br>
Настоящий Веб-сайт может содержать плагины социальных сетей, таких как Facebook, чтобы вы могли делиться контентом этих сайтов в социальных сетях. Если вы воспользуетесь этими компонентами, эти социальные сети также могут разместить файлы cookie в вашем браузере в рекламных целях.<br>
Дополнительно настоящий Веб-сайт использует веб-маяки (которые также называют пикселями, пиксельными тегами или GIF-файлами).<br>
Веб-маяки — это небольшие графические файлы (файлы GIF размером 1×1), которые встраиваются в интернет-страницы или электронные письма в формате HTML и обычно остаются невидимыми для пользователей. Обычно веб-маяки используются совместно с файлами cookie и позволяют отслеживать взаимодействие пользователей с контентом сайтов или информационных рассылок. Например, мы можем использовать веб-маяки, чтобы определить, открывались ли сообщения электронной почты или были ли сделаны переходы по определенным ссылкам, для определения тех или иных трендов и индивидуальных паттернов использования и для генерирования статистики использования Веб-сайта.</p>

<p>Управление в области передачи и приема файлов cookie<br>
Во многих случаях программное обеспечение для просмотра веб-страниц (веб-браузер) по умолчанию допускает хранение файлов cookie в вашем устройстве. Вы можете в любой момент внести изменения в настройки файлов cookie. Эти настройки могут быть изменены в настройках веб-браузера. Подробная информация о возможности и способах передачи файлов cookie доступна в настройках программного обеспечения (веб-браузера).</p>

<p>Как отказаться от использования cookie?<br>
В любое время вы можете запретить использование cookie в вашем браузере. Чтобы получить инструкции по блокировке, удалению или отключению файлов cookie, пожалуйста, используйте средства помощи и поддержки вашего браузера. Обратите внимание, что, удалив наши файлы cookie или отключив возможность загружать файлы cookie в будущем, вы можете не получить доступ к определенным областям или функциям нашего веб-сайта.<br>
Пожалуйста, ознакомьтесь со следующими ссылками для получения информации об управлении и блокировании файлов cookie в зависимости от вашего браузера<br>
Explorer: https://windows.microsoft.com/en-gb/internet-explorer/delete-manage-cookies#ie=ie-11.<br>
Firefox: https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences.<br>
Chrome: https://support.google.com/chrome/answer/95647?hl=en.<br>
Safari: https://support.apple.com/kb/PH19214?locale=en_GB.<br>
Вы можете запретить применение сторонних сетей отслеживания cookie, используя следующие инструменты:<br>
Кликните сюда, чтобы отказаться от использования Google Analytics<br>
Кликните сюда, чтобы отказаться от использования Яндекс.Метрика<br>
Кликните сюда, чтобы отказаться от использования AddThis</p>

<p>Обращаем ваше внимание — отключение поддержки файлов cookie, которые мы используем, может повлиять на функционал Веб-сайта.</p>

<br>
<p></p> -->
    </div>
</template>
<script>
export default {
    name: "fzf",
}
</script>
<style lang="scss">
.infcont{
    min-height: 100vh;
    padding: 1%;
    text-align: left;
}
</style>
